<template>
  <v-footer
    dark    
    padless
  >
    <v-card
      flat
      tile
      class="lighten-1 white--text text-center"
      color="#2E47CB"
    >
      <v-card-text>
        <!-- <v-btn
          v-for="icon in icons"
          :key="icon"
          class="mx-4 white--text"
          icon
        >
          <v-icon size="24px">
            {{ icon }}
          </v-icon>
        </v-btn> -->
      </v-card-text>

      <v-card-text class="white--text pt-0 px-16 hidden-sm-and-down">
        <v-row>
          <v-col cols="3">
            <v-card color="#2E47CB" elevation="0">
              <v-card-text>
                <span class="footertext">Our Department</span>
                <br><br>
                <a href="/divisions-and-services" class="footercontent">Divisions & Services</a>
                <br>
                <a href="/facilities" class="footercontent">Facilities</a>
                <br>
                <a href="/training" class="footercontent">Trainings</a>
                <br>
                <a href="/alumni" class="footercontent">Alumni</a>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="3">
            <v-card color="#2E47CB" elevation="0">
              <v-card-text>
                <span class="footertext">Our Mission</span>
                <br><br>
                <span class="footercontent">We commit to the unconditional delivery of excellent orthopedic care centered on the well-being of patients through competence in training.</span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="3">
            <v-card color="#2E47CB" elevation="0">
              <v-card-text>
                <span class="footertext">Our Vision</span>
                <br><br>
                <span class="footercontent">To be the primary choice for specialized orthopedic care in the Philippines.</span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="3">
            <v-card color="#2E47CB" elevation="0">
              <v-card-text>
              <span class="footertext">Contact Us</span>
                <br><br>
                <a href="/contact-us" class="footercontent">Rizal Avenue, San Lazaro Compound Sta. Cruz, Manila, Philippines<br><br>Tel. # (02) 8711 9491 local 221 | (02) 8244 4700<br><br>E-Mail: orthodocsjr@yahoo.com | jrrmmcortho@gmail.com | secretariat@jrortho.com.ph</a>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row> 
          <v-col >
            <v-divider class="footerdivider"></v-divider>
          </v-col>     
        </v-row>
      </v-card-text>

      <v-card-text class="white--text pt-0 px-16 hidden-md-and-up">
        <v-row>
          <v-col cols="12">
            <v-card color="#2E47CB" elevation="0">
              <v-card-text>
                <span class="footertext">Our Department</span>
                <br><br>
                <a href="/divisions-and-services" class="footercontent">Divisions & Services</a>
                <br>
                <a href="/facilities" class="footercontent">Facilities</a>
                <br>
                <a href="/training" class="footercontent">Trainings</a>
                <br>
                <a href="/alumni" class="footercontent">Alumni</a>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card color="#2E47CB" elevation="0">
              <v-card-text>
                <span class="footertext">Our Mission</span>
                <br><br>
                <span class="footercontent">We commit to the unconditional delivery of excellent orthopedic care centered on the well-being of patients through competence in training.</span>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card color="#2E47CB" elevation="0">
              <v-card-text>
                <span class="footertext">Our Vision</span>
                <br><br>
                <span class="footercontent">To be the primary choice for specialized orthopedic care in the Philippines.</span>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card color="#2E47CB" elevation="0">
              <v-card-text>
              <span class="footertext">Contact Us</span>
                <br><br>
                <a href="/contact-us" class="footercontent">Rizal Avenue, San Lazaro Compound Sta. Cruz, Manila, Philippines<br><br>Tel. # (02) 8711 9491 local 221 | (02) 8244 4700<br><br>E-Mail: orthodocsjr@yahoo.com | jrrmmcortho@gmail.com | secretariat@jrortho.com.ph</a>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>        
        <v-row> 
          <v-col >
            <v-divider class="footerdivider"></v-divider>
          </v-col>     
        </v-row>
      </v-card-text>

      

      <v-card-text class="white--text footercontentcopyright hidden-sm-and-down">
        <v-row>
          <v-col></v-col>
          <v-col cols="7">
            Copyright &copy; 2022 Jose R. Reyes Memorial Medical Center. All Rights Reserved | <a href="/privacy-policy" class="privpoltxt">Privacy Policy</a>
          </v-col>
          <v-col>
            <div class="row">
              <div class="column">
                <a href="https://www.facebook.com/jrrmmc.ortho" target="_blank">
                <!-- <router-link to="/facebook"> -->
                  <v-img
                    alt="Facebook"              
                    contain
                    src="assets/images/facebook.png"
                    transition="scale-transition"
                    width="50"             
                  />
                </a>
                <!-- </router-link> -->
              </div>
              <!-- <div class="column">
                <a href="https://www.twitter.com" target="_blank">
                  <v-img
                    alt="Twitter"              
                    contain
                    src="assets/images/twitter.png"
                    transition="scale-transition"
                    width="50"
                  />
                </a>
              </div> -->
              <div class="column">
                <a href="https://www.instagram.com/jrrmmc.ortho/" target="_blank">
                  <v-img
                    alt="Instagram"              
                    contain
                    src="assets/images/instagram.png"
                    transition="scale-transition"
                    width="50"
                  />
                </a>
              </div>
              <div class="column">
                <a href="https://www.youtube.com/channel/UCD14--kZD0WqjVdOIV4N-yg" target="_blank">
                  <v-img
                    alt="Youtube"              
                    contain
                    src="assets/images/youtube.png"
                    transition="scale-transition"
                    width="50"
                  />
                </a>
              </div>
            </div>
          </v-col>
          <v-col></v-col>        
          <!-- <v-col></v-col> -->
        </v-row>
        <br>
      </v-card-text>

      <v-card-text class="white--text footercontentcopyright1 hidden-md-and-up">
        <v-row align="center" justify="center">
          <v-col align="center">
            Copyright &copy; 2022 Jose R. Reyes Memorial Medical Center. All Rights Reserved | <a href="/privacy-policy" class="privpoltxt1">Privacy Policy</a>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <!-- <v-col cols='4'></v-col> -->
          <v-col align="center">
            <v-img
              alt="Facebook"              
              contain
              src="assets/images/facebook.png"
              transition="scale-transition"
              width="50"
            />
          </v-col>
          <v-col align="center">
            <v-img
              alt="Twitter"              
              contain
              src="assets/images/twitter.png"
              transition="scale-transition"
              width="50"
            />
          </v-col>
          <v-col align="center">
            <v-img
              alt="Instagram"              
              contain
              src="assets/images/instagram.png"
              transition="scale-transition"
              width="50"
            />
          </v-col>
          <v-col align="center">
            <v-img
              alt="Youtube"              
              contain
              src="assets/images/youtube.png"
              transition="scale-transition"
              width="50"
            />
          </v-col>          
        </v-row>
        <br>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
  export default {
    name: 'FooterBar',

    data: () => ({

    }),
  }
</script>

<style>

.footer {    
    left: 0;
    bottom: 0;
    width: 100%;
    height: 384px;
    background-color: #2E47CB;
    text-align: center;
    color: white;
    /* 
    -webkit-box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 1);
    -moz-box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 1);
    box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 1); */
}

.footertext {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400 !important;
    font-size: 1.75rem !important;  
    /* font-size: calc(1.5vh + 1.5vw + 1.5vmin); */
    /* line-height: 36px; */
    display: block;
    align-items: center;
    text-align: center;

    color: #FFFFFF;
}

.footercontent {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400 !important;
    font-size: 1rem !important;  
    /* font-size: calc(1vh + 1vw + 1vmin); */
    /* line-height: 24px; */
    display: block;
    align-items: center;
    text-align: center;
    color: #FFFFFF !important;
    text-decoration: none; 
}

.footercontentcopyright {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400 !important;
    font-size: 1.25rem !important; 
    /* font-size: 20px !important;   */
    line-height: 24px;
    display: block;
    align-items: left;
    text-align: left;
    color: #FFFFFF !important;
    /* margin-left: 0px; */
}

.footercontentcopyright1 {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400 !important;
    font-size: 1rem !important; 
    /* font-size: 20px !important;   */
    line-height: 24px;
    display: block;
    align-items: left;
    text-align: left;
    color: #FFFFFF !important;
    /* margin-left: 0px; */
}

.privpoltxt {
  text-decoration: none; 
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400 !important;
  font-size: 1.25rem !important; 
  color: #FFFFFF !important;
}

.privpoltxt1 {
  text-decoration: none; 
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400 !important;
  font-size: 1rem !important; 
  color: #FFFFFF !important;
}

.row {
  display: flex;
}

.column {
  flex: 25%;
  padding: 2px;
}

.footerdivider {
  border-width: 1px !important;
  border-color: white !important;
  height: 100%;
}

.row {
  display: flex;
  flex: 25%;
  padding: 2px;
}
</style>