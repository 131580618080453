<template>
  <v-container fluid class="divisionscontainer">
    <!-- <div> -->
      <v-row class="pt-8 px-16 hidden-sm-and-down" align="center" justify="center">
        <v-col
          align="center"           
          v-for="(i, index) in items"
          :key="index"
        >
          <v-card class="pa-4 mb-8 cardstyle" height="500">
            <v-card-title>
              <v-row>
                <v-col cols="12">
                  <div class="cardtitle">
                    {{ i.name }}
                </div>
                </v-col>
                <v-col cols="12">
                  <!-- <div> -->
                <v-img
                  alt="Division"              
                  contain
                  :src="photoChecker(image_filterer(i))"
                  transition="scale-transition"
                  height="200"
                  width="200"
                />
              <!-- </div> -->
                </v-col>
              </v-row>
              
              
              <!-- <v-spacer></v-spacer> -->
              
            </v-card-title>
            <v-card-text class="cardcontent">
              {{ content_filterer(i) }}
            </v-card-text>
            <v-card-actions class="mt-4 pa-4">
              <!-- <span class="cardactionspan">Schedule: Monday</span> -->
              <v-spacer></v-spacer>              
              <v-btn fab text color="primary" :href="link_filterer(i)" target="_blank">
                <v-icon size="4rem">
                  mdi-facebook
                </v-icon>
              </v-btn>
              <v-btn fab text color="primary" :href="messenger_filterer(i)" target="_blank">
                <v-icon size="4rem">
                  mdi-facebook-messenger
                </v-icon>
              </v-btn>
              <v-btn text color="primary" class="cardactionbutton" @click="showFaculty(i.name)">{{ viewChecker(i) }}</v-btn>
            </v-card-actions>            
          </v-card>     
        </v-col>
      </v-row>

      <v-row class="hidden-md-and-up" align="center" justify="center">
        <v-col
          align="center"           
          v-for="(i, index) in items"
          :key="index"
        >
          <v-card class="pa-2 mb-4 cardstyle">
            <!-- <v-card-title> -->
              <v-row align="center" justify="center">
                <v-col align="center" justify="center">
                  <div class="cardtitle1">
                    {{ i.name }}
                </div>
                </v-col>
              </v-row>
              <v-row align="center" justify="center">
                <v-col align="center" justify="center">
                  <!-- <div> -->
                    <v-img
                      alt="Division"              
                      contain
                      :src="photoChecker(image_filterer(i))"
                      transition="scale-transition"
                      width="50%"
                    />
                  <!-- </div> -->
                </v-col>
              </v-row>
              
              
              <!-- <v-spacer></v-spacer> -->
              
            <!-- </v-card-title> -->
            <v-card-text class="cardcontent1">
               {{ content_filterer(i) }}
            </v-card-text>

            <v-card-actions class="mt-4">
              <!-- <span class="cardactionspan">Schedule: Monday</span> -->
              <v-spacer></v-spacer>
              <v-btn fab color="primary" :href="link_filterer(i)" target="_blank">
                <v-icon large>
                  mdi-facebook
                </v-icon>
              </v-btn>
              <v-btn fab color="primary" :href="messenger_filterer(i)" target="_blank">
                <v-icon large>
                  mdi-facebook-messenger
                </v-icon>
              </v-btn>
              <v-btn fab color="primary" @click="showFaculty(i.name)">
                <v-icon large>
                  mdi-eye
                </v-icon>
              </v-btn>
            </v-card-actions>    
          </v-card>     
        </v-col>
      </v-row>
    <!-- </div> -->

    <v-dialog v-model="facultyDialog" width="1500" persistent>
      <v-card class="carddialogstyle hidden-sm-and-down">
        <v-card-title class="justify-center py-6">
          <v-spacer></v-spacer>
          <span class="carddialogtitle">
            {{ dialogTitle }}
          </span>
          <v-spacer></v-spacer>
          <v-btn fab @click="closeDialog">
            <v-icon color="black">
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="carddialogdivider"></v-divider>
        <v-card-text>
          <div class="mt-4" v-for="(m, mindex) in members.members_attributes"  :key="mindex">
            <v-row align="center">
              <v-col cols="3" class="mb-4">
                <v-img
                  alt="Faculty"              
                  contain
                  :src="photoChecker(m.info1)"
                  transition="scale-transition"
                  width="100%"
                  height="300"
                />
              </v-col>
              <v-col cols="9" align="center" justify="center">
                <v-row class="align-left doctorname">
                  <span>
                    {{ m.name }}
                  </span>
                </v-row>
                <v-row class="align-left doctorcategory mt-8">
                  <span>
                    <i>{{ m.info2 }}</i>
                  </span>
                </v-row>
                 <v-row class="align-left doctorcategory mt-8">
                  <span>
                    <i>{{ m.info3 }}</i>
                  </span>
                </v-row>
                <!-- <br><br>
                <v-row class="align-left doctorcategory">
                  <span>
                    {{ m }}
                  </span>
                </v-row>
                <br><br>
                <v-row class="align-left doctorschedule">
                  <span>
                    {{ m }}
                  </span>
                </v-row>
                <v-row class="align-left doctorschedule">
                  <span>                     
                    {{ m }}
                  </span>
                </v-row> -->
                <br>
              </v-col>              
            </v-row>
            <v-divider class="doctordivide"></v-divider>
          </div>
        </v-card-text>
      </v-card>

      <v-card class="carddialogstyle hidden-md-and-up">
        <v-card-title class="justify-center">
          <v-row align="center" justify="center">
            <v-col cols="12" align="center" justify="center">
                <span class="carddialogtitle">
                  {{ dialogTitle }}
                </span>              
                <v-btn fab @click="closeDialog">
                  <v-icon color="black" >
                    mdi-close
                  </v-icon>
                </v-btn>
            </v-col>
          </v-row>
          <!-- <v-row align="center" justify="center">
            <v-col cols="12" align="center" justify="center">
                <v-icon color="black" @click="closeDialog">
            mdi-close
          </v-icon>
            </v-col>
          </v-row> -->
          <!-- <v-spacer></v-spacer>
         
          <v-spacer></v-spacer> -->
          
        </v-card-title>
        <v-divider class="carddialogdivider"></v-divider>
        <v-card-text>
          <div class="mt-4" v-for="(m, mindex) in members.members_attributes"  :key="mindex">
            <v-row >            
              <v-col cols="12">
                <v-row  align="center" justify="center">
                  <v-col align="center" justify="center">
                    <span class="doctorname1">
                      {{ m.name }}
                    </span>
                    <br>
                    <span class="doctorcategory1">
                      <i>{{ m.info2 }}</i>
                    </span>
                    <br>
                    <span class="doctorcategory1">
                      <i>{{ m.info3 }}</i>
                    </span>
                  </v-col>
                </v-row>
                <v-row  align="center" justify="center">
                  <v-col align="center" justify="center">
                    
                  </v-col>
                </v-row>
                <v-row class="doctorcategory1" align="center" justify="center">
                  <v-col align="center" justify="center">
                    
                  </v-col>
                </v-row>
                <v-row align="center" justify="center">
                   <v-col align="center" justify="center">
                    <v-img
                      alt="Faculty"              
                      contain
                      :src="photoChecker(m.info1)"
                      transition="scale-transition"
                      width="100%"
                      height="200"
                    />
                  </v-col>
                </v-row>
                <!-- <br><br>
                <v-row class="align-left doctorcategory">
                  <span>
                    {{ m }}
                  </span>
                </v-row>
                <br><br>
                <v-row class="align-left doctorschedule">
                  <span>
                    {{ m }}
                  </span>
                </v-row>
                <v-row class="align-left doctorschedule">
                  <span>                     
                    {{ m }}
                  </span>
                </v-row> -->
                <br>
              </v-col>              
            </v-row>
            <v-divider class="doctordivide"></v-divider>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// import News from "../apis/News";
import Pages from "../apis/Pages";
import Faculty from "../apis/Faculty";
import { mapGetters } from "vuex";

  export default {
    name: 'DivisionsServices',

    data: () => ({
      facultyDialog: false,
      items1: [
        {
          name: 'Joseph Paulo P. Sales',
          category: 'Spine and Pediatric Orthopedics, and Ilizarov Surgery',
          clinic: 'Jose R. Reyes Memorial Medical Center',
          schedule: 'Monday - Friday: 10:00 AM - 2:00 PM', 
        },
        {
          name: 'Joseph Paulo P. Sales',
          category: 'Spine and Pediatric Orthopedics, and Ilizarov Surgery',
          clinic: 'Jose R. Reyes Memorial Medical Center',
          schedule: 'Monday - Friday: 10:00 AM - 2:00 PM', 
        },
        {
          name: 'Joseph Paulo P. Sales',
          category: 'Spine and Pediatric Orthopedics, and Ilizarov Surgery',
          clinic: 'Jose R. Reyes Memorial Medical Center',
          schedule: 'Monday - Friday: 10:00 AM - 2:00 PM', 
        },
        {
          name: 'Joseph Paulo P. Sales',
          category: 'Spine and Pediatric Orthopedics, and Ilizarov Surgery',
          clinic: 'Jose R. Reyes Memorial Medical Center',
          schedule: 'Monday - Friday: 10:00 AM - 2:00 PM', 
        },
        {
          name: 'Joseph Paulo P. Sales',
          category: 'Spine and Pediatric Orthopedics, and Ilizarov Surgery',
          clinic: 'Jose R. Reyes Memorial Medical Center',
          schedule: 'Monday - Friday: 10:00 AM - 2:00 PM', 
        },
      ],
      photo: '/assets/images/JRRMMC_Ortho_Logo.jpg',
      dialogTitle: '',
    }),

    computed: {
      ...mapGetters({
        items: "getItems", 
        members: "getMembers", 
        // queue_clinic: "getQueueClinic", 
        // queue_service: "getQueueService",
      }),
    },

    created () {        
        this.getPageContent()
    },

    methods: {
      getPageContent() {
        Pages.get_page('divisions')
        .then(response => {                
                this.$store.commit('ITEMS', response.data.components_attributes);   
                // this.items =  response.data            
                // console.log('test ', response.data.components_attributes)
            })
            .catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                }
                else if (error.response.status === 404) {
                    this.errors = error.response.data.errors;
                    this.$store.commit('ITEMS', []);   
                }
            });
      },

      photoChecker(photo) {
        if(photo == '' || photo == null) {
          return this.photo
        }
        else {
          return photo
        }
      },

      showFaculty(name) {
        // console.log(name)
        Faculty.get_faculty(name)
        .then(response => {                
            this.$store.commit('MEMBERS', response.data);   
            // this.getDivisionNames()
            // this.items =  response.data            
            // console.log('test ', response.data)
            this.dialogTitle = name
            this.facultyDialog = true
        })
        .catch(error => {
            if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                // this.snackbar_text = "Unable to load clinics!"
                // this.snackbar_color = 'red darken-2'
                // this.snackbar = true
            }
            else if (error.response.status === 404) {
                this.errors = error.response.data.errors;
                this.$store.commit('MEMBERS', []);   
                // this.items = []
                // this.snackbar_text = "Unable to load clinics!"
                // this.snackbar_color = 'red darken-2'
                // this.snackbar = true
            }
        });
        // if (index == 1) {
          // alert('ilizarov')          
        // }
      },

      closeDialog() {
        this.facultyDialog = false
      },

      image_filterer(payload) {
          var filtered_images = []
          filtered_images.push(payload.parts_attributes.filter(key => (key.type_of == 'Image')))
          if(filtered_images[0] == '') {
            return  ''        
          }
          else {
            return filtered_images[0][0].value
          }          
       },

       content_filterer(payload) {
          var filtered_content = []
          filtered_content.push(payload.parts_attributes.filter(key => (key.type_of == 'Content')))
          if(filtered_content[0] == '') {
            return  ''        
          }
          else {
            return filtered_content[0][0].value
          }            
       },

       link_filterer(payload) {
          var filtered_link = []
          filtered_link.push(payload.parts_attributes.filter(key => (key.type_of == 'Link')))
          // return filtered_content[0][0].value

          if(filtered_link[0] == '') {
            return  'https://www.facebook.com/jrrmmc.ortho'        
          }
          else {
            return filtered_link[0][0].value
          }
       },

       messenger_filterer(payload) {
          var filtered_link = []
          filtered_link.push(payload.parts_attributes.filter(key => (key.type_of == 'Messenger')))
          // return filtered_content[0][0].value

          if(filtered_link[0] == '') {
            return  'https://m.me/jrrmmc.ortho'        
          }
          else {
            return filtered_link[0][0].value
          }
       },

       viewChecker(payload) {
          if(payload.name == 'CLUBFOOT CLINIC BY MIRACLE FEET') {
            return 'View Coordinator'
          }
          else {
            return 'View Faculty'
          }          
       },
    },
  }
</script>

<style scoped>
.divisionscontainer {
  background: #E5E5E5;
}
.cardstyle {
  width:800px;
  /* height:400px; */
  background: #FFFFFF;
  border: 2px solid #000000;
  box-sizing: border-box;
  /* default */
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.cardtitle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 1.75rem;
  line-height: 32px;
  /* identical to box height, or 160% */
  /* letter-spacing: 0.25px; */
    white-space: nowrap ;
    word-break: break-word;
    overflow: hidden ;
    text-overflow: ellipsis;
}

.cardtitle1 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 32px;
  /* identical to box height, or 160% */
  /* letter-spacing: 0.25px; */
    /* white-space: nowrap ; */
    word-break: break-word;
    /* overflow: hidden ; */
    /* text-overflow: ellipsis; */
}

.cardcontent {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  text-align: left !important;
  /* line-height: 22px; */
  /* or 157% */
  letter-spacing: 0.1px;
  color: #000000 !important;
  display: inline-block;
  /* border: 1px solid #cccccc; */
  /* padding: 10px; */
  height: 90px;
  width: 100%;
  /* height: 325px; */
  -webkit-line-clamp: 4;
  display: -webkit-box;
  /* line-height: 1.5; */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis !important;
  /* font-size: 1rem; */
  text-align: justify !important;
}

.cardcontent1 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  text-align: left !important;
  /* line-height: 22px; */
  /* or 157% */
  letter-spacing: 0.1px;
  color: #000000 !important;
  display: inline-block;
  /* border: 1px solid #cccccc; */
  /* padding: 10px; */
  height: 80px;
  width: 100%;
  /* height: 325px; */
  -webkit-line-clamp: 3;
  display: -webkit-box;
  /* line-height: 1.5; */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis !important;
  /* font-size: 1rem; */
  text-align: justify !important;
}

.cardactionspan {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* or 157% */
  letter-spacing: 0.1px;
  /* black/0.6 */
  color: rgba(0, 0, 0, 0.6);
}

.cardactionbutton {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 1.25px;

}

.carddialogstyle{
  background: #FFFFFF;
  border: 2px solid #000000;
  /* box-sizing: border-box; */
  /* default */
  /* box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12); */
  /* border-radius: 4px; */
}

.carddialogdivider {
  border-width: 1px !important;
  border-color: black !important;
  height: 100%;
}

.carddialogtitle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 800;
  font-size: 1.25rem !important;
  font-size: calc(1vw + 2vh) !important;;
  line-height: 32px;  
  /* or 64% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 2px;
  word-break: break-word;
}

.doctorname {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 2.5rem;
  line-height: 32px;
  /* identical to box height, or 80% */

  letter-spacing: 0.25px;

  color: #2E47CB;
}

.doctorname1 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 32px;
  /* identical to box height, or 80% */

  letter-spacing: 0.25px;

  color: #2E47CB;
}

.doctorcategory {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 2rem;
  line-height: 22px;
  /* identical to box height, or 92% */

  letter-spacing: 0.1px;

  color: #000000;
}

.doctorcategory1 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  /* line-height: 22px; */
  /* identical to box height, or 92% */

  letter-spacing: 0.1px;

  color: #000000;
}

.doctorschedule {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 22px;
  /* or 122% */

  letter-spacing: 0.1px;
}

.doctordivide {
  border: none;
  border-top: 2px dotted #000000;
  color: #fff;
  background-color: #fff;
  height: 1px;
  width: 100%;
}
</style>