import Api from "./Api";

export default {
    get_page(slug) {
        return Api().get(`/pages/` + slug);
    },

    post_page(slug, payload) {
        return Api().patch(`admin/pages/` + slug, {components_attributes: [payload]});
    },

    update_page(slug, payload) {
        return Api().put(`admin/pages/` + slug, {components_attributes: [payload]});
        // return Api().delete("/pages/home", payload);
    },

    delete_page(slug, payload) {
        return Api().delete(`admin/pages/` + slug + `/` + payload.id, payload);
        // return Api().delete("/pages/home", payload);
    },

    delete_component(payload) {
        return Api().delete(`/admin/components/` + payload.id);
        // return Api().delete("/pages/home", payload);
    },

    delete_part(slug, payload) {
        return Api().patch(`admin/pages/` + slug, {components_attributes: [payload]});
        // return Api().delete("/pages/home", payload);
    },
}