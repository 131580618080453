<template>
  <v-container fluid class="trainingcontainer">
    <v-container fluid class="px-8 hidden-sm-and-down">
      <v-card class="py-2">
        <v-card-title class="trainingtitle">Training</v-card-title>
        <v-card-text class="trainingcontent">
          <p>JRRMMC Department of Orthopaedics offers a four-year residency training program with rotations in Trauma, Adult Orthopaedics, Pediatric Orthopaedics, Spine, Hand and Microvascular, Musculoskeletal Tumor, Sports Medicine, MSK Infections, Ilizarov, Shoulder, Foot and Ankle and Shoulder.</p>          
          <p>Residents are taught through weekly department and team conferences, patient rounds, lectures and hands-on workshops. Trainees are able to handle patients in the Operating Room, in the Outpatient Clinic as well as in the wards.</p>
        </v-card-text>
      </v-card>

      <v-card class="my-4">
          <v-card-title class="trainingtitle">
            Resident Doctors
          </v-card-title>
          <v-card-text>
            <v-row class="mb-8" align="center" justify="center">              
              <!-- <v-col>         -->
                <v-card elevation="0" class="mb-8" height="100%">
                  <v-row align="center" justify="center">
                    <h1>Chief Resident</h1>
                  </v-row>
                  <v-row align="center" justify="center">                    
                    <v-col 
                      align="center" justify="center"
                      cols="3"
                      v-for="(rc, rcindex) in residentsChief"
                      :key="rcindex"
                    >                      
                      <v-img                                                            
                      alt="Resident"                                              
                      :src="image_filterer(rc)"
                      transition="scale-transition"     
                      contain    
                      height="300"
                      
                      />   
                      <v-card-title class="blue--text justify-center text-h5 nametxt"><b>{{ rc.name }}</b></v-card-title>     
                      <!-- <v-card-text class="red--text text-center text-h6"><i>{{ content_filterer(r4) }}</i></v-card-text>             -->
                    </v-col>
                  </v-row>
                  <v-row align="center" justify="center">
                    <h1>Fourth Year Residents</h1>
                  </v-row>
                  <v-row align="center" justify="center">                    
                    <v-col 
                      align="center" justify="center"
                      cols="3"
                      v-for="(r4, r4index) in residents4th.slice(1,3)"
                      :key="r4index"
                    >                      
                      <v-img                                                            
                      alt="Resident"                                              
                      :src="image_filterer(r4)"
                      transition="scale-transition"     
                      contain    
                      height="300"
                      
                      />   
                      <v-card-title class="blue--text justify-center text-h5 nametxt"><b>{{ r4.name }}</b></v-card-title>     
                      <!-- <v-card-text class="red--text text-center text-h6"><i>{{ content_filterer(r4) }}</i></v-card-text>             -->
                    </v-col>
                  </v-row>
                  <v-row align="center" justify="center">
                    <h1>Third Year Residents</h1>
                  </v-row>
                  <v-row align="center" justify="center">
                    <v-col 
                      align="center" justify="center"
                      cols="3"
                      v-for="(r3, r3index) in residents3rd"
                      :key="r3index"
                    >                      
                      <v-img                                                            
                      alt="Resident"                                              
                      :src="image_filterer(r3)"
                      transition="scale-transition"     
                      contain    
                      height="300"
                      
                      />   
                      <v-card-title class="blue--text justify-center text-h5 nametxt"><b>{{ r3.name }}</b></v-card-title>     
                      <!-- <v-card-text class="red--text text-center text-h6"><i>{{ content_filterer(r3) }}</i></v-card-text>             -->
                    </v-col>
                  </v-row>
                 <v-row align="center" justify="center">
                    <h1>Second Year Residents</h1>
                  </v-row>
                  <v-row align="center" justify="center">
                    <v-col 
                      align="center" justify="center"
                      cols="3"
                      v-for="(r2, r2index) in residents2nd"
                      :key="r2index"
                    >                      
                      <v-img                                                            
                      alt="Resident"                                              
                      :src="image_filterer(r2)"
                      transition="scale-transition"     
                      contain    
                      height="300"
                      
                      />   
                      <v-card-title class="blue--text justify-center text-h5 nametxt"><b>{{ r2.name }}</b></v-card-title>     
                      <!-- <v-card-text class="red--text text-center text-h6"><i>{{ content_filterer(r2) }}</i></v-card-text>             -->
                    </v-col>
                  </v-row>
                  <v-row align="center" justify="center">
                    <h1>First Year Residents</h1>
                  </v-row>
                  <v-row align="center" justify="center">
                    <v-col 
                      align="center" justify="center"
                      cols="3"
                      v-for="(r1, r1index) in residents1st"
                      :key="r1index"
                    >                      
                      <v-img                                                            
                      alt="Resident"                                              
                      :src="image_filterer(r1)"
                      transition="scale-transition"     
                      contain    
                      height="300"             
                      />   
                      <v-card-title class="blue--text justify-center text-h5 nametxt"><b>{{ r1.name }}</b></v-card-title>     
                      <!-- <v-card-text class="red--text text-center text-h6"><i>{{ content_filterer(r1) }}</i></v-card-text>             -->
                    </v-col>
                  </v-row>                  
                </v-card>        
              <!-- </v-col> -->
            </v-row>  
          </v-card-text>
        </v-card> 

        <v-card class="my-4">
          <v-card-title class="trainingtitle">
            Training Committee
          </v-card-title>
          <v-card-text>
            <v-row align="center" justify="center">
              <v-col 
                align="center" justify="center"
                cols="3"
                v-for="(r1, r1index) in trainingcommittee"
                :key="r1index"
              >                      
                <v-img                                                            
                alt="Resident"                                              
                :src="image_filterer(r1)"
                transition="scale-transition"     
                contain  
                width="200px"     
                height="300"             
                />   
                <v-card-title class="blue--text justify-center text-h5 nametxt"><b>{{ r1.name }}</b></v-card-title>     
                <!-- <v-card-text class="red--text text-center text-h6"><i>{{ content_filterer(r1) }}</i></v-card-text>             -->
              </v-col>
            <!-- <v-col cols="3" align="center" justify="center">
              <v-img                        
                class="facilitiesimage"
                alt="Facebook"                                
                :src="photoChecker(image_filterer(items[15]))"
                transition="scale-transition"     
                height="300px" 
                width="200px"   
                            
              /> 
              <h1 class="blue--text justify-center text-h5 doctxt"><b>{{ items[15].name }}</b></h1>
              <h2>{{ content_filterer(items[15]) }}</h2>
            </v-col>
            <v-col cols="3" align="center" justify="center">
              <v-img                        
                class="facilitiesimage"
                alt="Facebook"                                
                :src="photoChecker(image_filterer(items[16]))"
                transition="scale-transition"     
                height="300px"  
                width="200px"
                            
              /> 
              <h1 class="blue--text justify-center text-h5 doctxt"><b>{{ items[16].name }}</b></h1>
              <h2>{{ content_filterer(items[16]) }}</h2>
            </v-col>
            <v-col cols="3" align="center" justify="center">
              <v-img                        
                class="facilitiesimage"
                alt="Facebook"                                
                :src="photoChecker(image_filterer(items[17]))"
                transition="scale-transition"     
                height="300px" 
                width="200px"
                            
              /> 
              <h1 class="blue--text justify-center text-h5 doctxt"><b>{{ items[17].name }}</b></h1>
              <h2>{{ content_filterer(items[17]) }}</h2>
            </v-col>             -->
          </v-row>
          </v-card-text>
        </v-card>

        <v-card 
          v-for="(a, ai) in items.slice(0,2)"
          :key="ai"
          class="my-4"
        >
          <v-card-title>
            <span class="trainingheader">{{ a.name }}</span>
          </v-card-title>
          <v-card-text>
            <v-carousel continuous :cycle="true" hide-delimiters>
              <v-carousel-item
                v-for="(item,i) in a.parts_attributes"
                :key="i"
                :src="item.value"
                reverse-transition="fade-transition"
                transition="fade-transition"      
                contain                  
              >    
              </v-carousel-item>
            </v-carousel>
          </v-card-text>
        </v-card>
      
      <!-- <v-expansion-panels focusable class="my-4">
        <v-expansion-panel
          v-for="(a, ai) in items.slice(0,2)"
          :key="ai"
        >
          <v-expansion-panel-header><span class="trainingheader">{{ a.name }}</span></v-expansion-panel-header>
          <v-expansion-panel-content class="trainingcontent py-2">
            <v-carousel continuous :cycle="true" hide-delimiters>
              <v-carousel-item
                v-for="(item,i) in a.parts_attributes"
                :key="i"
                :src="item.value"
                reverse-transition="fade-transition"
                transition="fade-transition"      
                contain                  
              > 
              </v-carousel-item>
            </v-carousel>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels> -->
                     
    </v-container>
    <v-container fluid class="hidden-md-and-up">
      <v-card class="py-2">
        <v-card-title class="trainingtitle">Training</v-card-title>
        <v-card-text class="trainingcontent1">
          <p>JRRMMC Department of Orthopaedics offers a four-year residency training program with rotations in Trauma, Adult Orthopaedics, Pediatric Orthopaedics, Spine, Hand and Microvascular, Musculoskeletal Tumor, Sports Medicine, MSK Infections, Ilizarov, Shoulder, Foot and Ankle and Shoulder.</p>          
          <p>Residents are taught through weekly department and team conferences, patient rounds, lectures and hands-on workshops. Trainees are able to handle patients in the Operating Room, in the Outpatient Clinic as well as in the wards.</p>
        </v-card-text>
      </v-card>

      <v-card class="my-4">
          <v-card-title class="trainingtitle">
            Resident Doctors
          </v-card-title>
          <v-card-text>
            <v-row class="mb-8">
              <v-col>        
                <v-card elevation="0" class="mb-8" height="100%">
                  <v-row align="center" justify="center">
                    <v-col 
                      align="center" justify="center"
                      cols="12" 
                      v-for="(n, nindex) in residents.slice(0,14)"
                      :key="nindex"
                    >                      
                      <v-img                                                            
                      alt="Resident"                                              
                      :src="image_filterer(n)"
                      transition="scale-transition"     
                      contain    
                      width="50%"
                      
                      />   
                      <v-card-title class="blue--text justify-center text-h6 nametxt"><b>{{ n.name }}</b></v-card-title>     
                      <v-card-text class="blue--text text-center text-h7"><i>{{ content_filterer(n) }}</i></v-card-text>            
                    </v-col>
                  </v-row>             
                  
                </v-card>        
              </v-col>
            </v-row>  
          </v-card-text>
        </v-card> 

        <v-card class="my-4">
          <v-card-title class="trainingtitle">
            Training Committee
          </v-card-title>
          <v-card-text>
            <v-row align="center" justify="center">
              <v-col 
                align="center" justify="center"
                cols="12"
                v-for="(r1, r1index) in trainingcommittee"
                :key="r1index"
              >                      
                <v-img                                                            
                alt="Resident"                                              
                :src="image_filterer(r1)"
                transition="scale-transition"     
                contain    
                width="50%"         
                />   
                <v-card-title class="blue--text justify-center text-h5 nametxt"><b>{{ r1.name }}</b></v-card-title>     
                <!-- <v-card-text class="red--text text-center text-h6"><i>{{ content_filterer(r1) }}</i></v-card-text>             -->
              </v-col>
            <!-- <v-col align="center" justify="center">
              <v-img                        
                class="facilitiesimage"
                alt="Facebook"                                
                :src="photoChecker(image_filterer(items[15]))"
                transition="scale-transition"     
                height="300px" 
                width="200px"   
                            
              /> 
              <h2 class="blue--text justify-center text-h6 doctxt">{{ items[15].name }}</h2>
              <h3 class="mb-4">{{ content_filterer(items[15]) }}</h3>          
              <v-img                        
                class="facilitiesimage"
                alt="Facebook"                                
                :src="photoChecker(image_filterer(items[16]))"
                transition="scale-transition"     
                height="300px"  
                width="200px"
                            
              /> 
              <h2 class="blue--text justify-center text-h6 doctxt">{{ items[16].name }}</h2>
              <h3 class="mb-4">{{ content_filterer(items[16]) }}</h3>        
              <v-img                        
                class="facilitiesimage"
                alt="Facebook"                                
                :src="photoChecker(image_filterer(items[17]))"
                transition="scale-transition"     
                height="300px" 
                width="200px"
                            
              /> 
              <h2 class="blue--text justify-center text-h6 doctxt">{{ items[17].name }}</h2>
              <h3 class="mb-4">{{ content_filterer(items[17]) }}</h3>
            </v-col>             -->
          </v-row>
          </v-card-text>
        </v-card>

        <v-card
          class="my-4"
          v-for="(a, ai) in items.slice(0,2)"
          :key="ai"
        >
          <v-card-title>
            <span class="trainingheader">{{ a.name }}</span>
          </v-card-title>
          <v-card-text>
            <v-carousel continuous :cycle="true" hide-delimiters>
              <v-carousel-item
                v-for="(item,i) in a.parts_attributes"
                :key="i"
                :src="item.value"
                reverse-transition="fade-transition"
                transition="fade-transition"      
                contain                  
              > 
              <!-- <v-row class="fill-height" align="center" justify="center">
                <div class="display-3 white--text">{{ item.name }}</div>
              </v-row>                        -->
              </v-carousel-item>
            </v-carousel>
          </v-card-text>
        </v-card>

      <!-- <v-expansion-panels focusable class="my-4">
        <v-expansion-panel
          v-for="(a, ai) in items.slice(0,2)"
          :key="ai"
        >
          <v-expansion-panel-header><span class="trainingheader">{{ a.name }}</span></v-expansion-panel-header>
          <v-expansion-panel-content class="trainingcontent py-2">
            <v-carousel continuous :cycle="true" hide-delimiters>
              <v-carousel-item
                v-for="(item,i) in a.parts_attributes"
                :key="i"
                :src="item.value"
                reverse-transition="fade-transition"
                transition="fade-transition"      
                contain                  
              > 
              </v-carousel-item>
            </v-carousel>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels> -->
      
    </v-container>
  </v-container>
</template>

<script>
import Pages from "../apis/Pages";
import { mapGetters } from "vuex";

  export default {
    name: 'HelloWorld',

    data: () => ({
      items1: [
        {
          url: '/assets/images/training_image.png',
          title: 'Training Test 1',
          content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
        {
          url: '/assets/images/training_image.png',
          title: 'Training Test 2',
          content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
        {
          url: '/assets/images/training_image.png',
          title: 'Training Test 3',
          content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
      ],     
      model: null, 
      photo: '/assets/images/training_image.png',
      residents: [],    
      filteredImages: [],  
      residentsChief: [],
      residents4th: [],
      residents3rd: [],
      residents2nd: [],
      residents1st: [],
      trainingcommittee: [],
    }),

    computed: {
      ...mapGetters({
        items: "getItems",
        // queue_clinic: "getQueueClinic", 
        // queue_service: "getQueueService",
      }),
    },

    created () {                
        this.getPageContent()
    },

    methods: {
      getPageContent() {
        Pages.get_page('training')
        .then(response => {                
                this.$store.commit('ITEMS', response.data.components_attributes); 
                let l =  response.data.components_attributes.length
                this.residents = response.data.components_attributes.slice(2,l)    
                // var arr = [{"nid":"31","0":{"tid":"20","name":"Bench Press","objectDate":"2012-02-08","goal":"rep","result":"55.00","comments":"sick!","maxload":"250"},"1":{"tid":"22","name":"Back Squat","objectDate":"2012-02-08","goal":"time","result":"8.00","comments":"i was tired.","maxload":"310"}},{"nid":"30","0":{"tid":"19","name":"Fran","objectDate":"2012-02-07","goal":"time","result":"5.00","comments":null}}];
                // console.log('test ', this.residents)
                this.residentsChief = this.filterByProperty(this.residents, "value", "Chief Resident")
                this.residents4th = this.filterByProperty(this.residents, "value", "4th Year Resident")
                this.residents3rd = this.filterByProperty(this.residents, "value", "3rd Year Resident")
                this.residents2nd = this.filterByProperty(this.residents, "value", "2nd Year Resident")
                this.residents1st = this.filterByProperty(this.residents, "value", "1st Year Resident")
                this.trainingcommittee = this.filterByProperty(this.residents, "value", "Training Committee")
                // this.filterByProperty(this.residents, "value", "3rd")    
                
                // this.filterByProperty(arr, "name", "Fran");
                // this.filterByProperty(arr, "goal", "time");

                // this.names =  response.data.components_attributes[4] 
                // console.log('test ', response.data.components_attributes)
            })
            .catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                }
                else if (error.response.status === 404) {
                    this.errors = error.response.data.errors;
                    this.$store.commit('ITEMS', []);   
                }
            });
      },      

      photoChecker(photo) {
        if(photo == '' || photo == null) {
          return this.photo
        }
        else {
          return photo
        }
      },   

       image_filterer(payload) {
          var filtered_images = []
          filtered_images.push(payload.parts_attributes.filter(key => (key.type_of == 'Image')))
          if(filtered_images[0] == '') {
            return  ''        
          }
          else {
            return filtered_images[0][0].value
          }  
       },

       content_filterer(payload) {
          var filtered_content = []
          filtered_content.push(payload.parts_attributes.filter(key => (key.type_of == 'Content')))
          if(filtered_content[0] == '') {
            return  ''        
          }
          else {
            return filtered_content[0][0].value
          }   
       },

       filterByProperty(array, prop, value){
            // console.log('arr', array)
            // console.log('prp', prop)
            // console.log('vle', value)
            var filtered = [];
            for(var i = 0; i < array.length; i++){

                var obj = array[i];
                // console.log('obj', obj.parts_attributes)
                for(var key in obj.parts_attributes){
                  // console.log('objPAR', obj[key].parts_attributes)
                  // eslint-disable-next-line
                    if(typeof(obj[key] == "object")){
                        var item = obj.parts_attributes[key];
                        // console.log('itm1', item)
                        if(item[prop].match(value)){
                          // console.log('itm2', item)
                            filtered.push(obj);
                        }
                    }
                }

            }    
            // console.log('fltrd', filtered)
            return filtered;

        }        
    }
  }
</script>

<style scoped>
.trainingcontainer {
  background: #E5E5E5;
}
.trainingtitle {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 32px;
    /* identical to box height, or 160% */
    letter-spacing: 0.25px;
    color: #0D09F0;
}

.trainingheader {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 32px;
    /* identical to box height, or 160% */
    letter-spacing: 0.25px;
    color: #0D09F0;
}


.trainingcard {
  background: #FFFFFF;
  /* border: 1px solid #000000; */
  box-sizing: border-box;
  border-radius: 5px;
  /* height: 750px;   */
  
}

.trainingcontent {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    text-align: left !important;
    /* line-height: 22px; */
    /* or 157% */
    letter-spacing: 0.1px;
    color: #000000 !important;
    display: inline-block;
    /* border: 1px solid #cccccc; */
    /* padding: 10px; */
    /* height: 90px;
    width: 100%; */
    /* height: 325px; */
    -webkit-line-clamp: 4;
    display: -webkit-box;
    /* line-height: 1.5; */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis !important;
    /* font-size: 1rem; */
    word-break: break-word;
}

.trainingcontent1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    text-align: justify !important;
    /* line-height: 22px; */
    /* or 157% */
    letter-spacing: 0.1px;
    color: #000000 !important;
    display: inline-block;  
    word-break: break-word;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.borderblack {
  border: 1px solid #000000 !important;
}

.v-expansion-panel-header--active {
  color:yellow !important;
}

.nametxt{  
  word-break: break-word;
  color: blue !important
}

.doctxt{  
  word-break: break-word;
  color: black !important
}
</style>