import Vue from 'vue'
import VueRouter from 'vue-router'

import Admin from '../components/Admin.vue'
import Dashboard from '../components/Dashboard.vue'

import Home from '../views/Home.vue'
import DivisionsServices from '../views/DivisionsServices.vue'
import Facilities from '../views/Facilities.vue'
import Training from '../views/Training.vue'
import CharityServices from '../views/CharityServices.vue'
import Alumni from '../views/Alumni.vue'
import Research from '../views/Research.vue'
import PostGraduateCourse from '../views/PostGraduateCourse.vue'
import PreResidency from '../views/PreResidency.vue'
import News from '../views/News.vue'
import ContactUs from '../views/ContactUs.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'

Vue.use(VueRouter)

let routes = [
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    meta: {title: 'JRRMMC | Department of Orthopaedics | Admin', authOnly: false,  header: 1}
  },
  {
    path: '/admin/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {title: 'JRRMMC | Department of Orthopaedics | Dashboard', authOnly: true,  header: 2}
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {title: 'JRRMMC | Department of Orthopaedics | Home', authOnly: false,  header: 0}
  },
  {
    path: '/divisions-and-services',
    name: 'DivisionsServices',
    component: DivisionsServices,
    meta: {title: 'JRRMMC | Department of Orthopaedics | Divisions and Services', authOnly: false, header: 0}
  },
  {
    path: '/facilities',
    name: 'Facilities',
    component: Facilities,
    meta: {title: 'JRRMMC | Department of Orthopaedics | Facilities', authOnly: false, header: 0}
  },
  {
    path: '/training',
    name: 'Training',
    component: Training,
    meta: {title: 'JRRMMC | Department of Orthopaedics | Training', authOnly: false, header: 0}
  },
  {
    path: '/charity-services',
    name: 'CharityServices',
    component: CharityServices,
    meta: {title: 'JRRMMC | Department of Orthopaedics | Charity Services', authOnly: false, header: 0}
  },
  {
    path: '/alumni',
    name: 'Alumni',
    component: Alumni,
    meta: {title: 'JRRMMC | Department of Orthopaedics | Alumni', authOnly: false, header: 0}
  },
  {
    path: '/research',
    name: 'Research',
    component: Research,
    meta: {title: 'JRRMMC | Department of Orthopaedics | Research', authOnly: false, header: 0}
  },
  {
    path: '/post_grad',
    name: 'PostGraduateCourse',
    component: PostGraduateCourse,
    meta: {title: 'JRRMMC | Department of Orthopaedics | Post Graduate Course', authOnly: false, header: 0}
  },
  {
    path: '/pre-residency',
    name: 'PreResidency',
    component: PreResidency,
    meta: {title: 'JRRMMC | Department of Orthopaedics | Pre-Residency', authOnly: false, header: 0}
  },
  {
    path: '/news',
    name: 'News',
    component: News,
    meta: {title: 'JRRMMC | Department of Orthopaedics | News', authOnly: false, header: 0}
  },
  {
    path: '/contact-us',
    name: 'ContactUs',
    component: ContactUs,
    meta: {title: 'JRRMMC | Department of Orthopaedics | Contact Us', authOnly: false, header: 0}
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
    meta: {title: 'JRRMMC | Department of Orthopaedics | Privacy Policy', authOnly: false, header: 0}
  },
  {
    path: '/facebook',
    beforeEnter() {location.href = 'https://www.facebook.com/jrrmmc.ortho'}
}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

function isLoggedIn() {
  return sessionStorage.getItem("token");
}

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.authOnly)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!isLoggedIn()) {
      next({
        path: "/admin",
        query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else if (to.matched.some(record => record.meta.guestOnly)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (isLoggedIn()) {
      next({
        path: "/",
        query: { redirect: to.fullPath }
      });
    } 
    else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});


export default router