<template>
  <v-container fluid>
      <!-- <div> -->
          <v-row class="pt-8 px-16 hidden-sm-and-down" align="center" justify="center">
              <v-col align="center" justify="center">
                <v-card elevation="8" class="pt-4 pl-4">
                  <v-card-title class="charitytitle">Post Graduate Course</v-card-title>           
                  <v-card-text>
                    <v-img                        
                        class="facilitiesimage mb-4"
                        alt="Facebook"                                
                        :src="photoChecker(image_filterer(items[3]))"
                        transition="scale-transition"     
                        width="50%"    
                        contain             
                    /> 
                    <!-- <v-img                        
                        class="facilitiesimage mb-4"
                        alt="Facebook"                                
                        :src="photoChecker(image_filterer(items[0]))"
                        transition="scale-transition"     
                        width="50%"    
                        contain             
                    />  -->
                    <!-- <v-img                        
                        class="facilitiesimage mb-4"
                        alt="Facebook"                                
                        :src="photoChecker(image_filterer(items[1]))"
                        transition="scale-transition"     
                        width="50%"    
                        contain             
                    />  -->
                    <!-- <div class="mb-4">
                        <iframe src="https://drive.google.com/file/d/1DvmykC3IVHPrnjWGUUo-2ioRyd-HKxIP/preview" width="50%" height="500" allow="autoplay"></iframe>               
                    </div>                   
                    <div class="mb-4">
                        <iframe src="https://drive.google.com/file/d/1OOqLCmb34wSlaeLFJmFOjqeuJtQmVoXh/preview" width="50%" height="500" allow="autoplay"></iframe>    
                    </div>
                    <div class="mb-4">
                        <iframe src="https://drive.google.com/file/d/13nSYJCQLTMrGn76STrQiFc0haUwiV0Wd/preview" width="50%" height="500" allow="autoplay"></iframe>                    
                    </div>                        -->
                    <v-img                        
                        class="facilitiesimage mb-4"
                        alt="Facebook"                                
                        :src="photoChecker(image_filterer(items[2]))"
                        transition="scale-transition"     
                        width="50%"    
                        contain             
                    /> 
                    
                  </v-card-text>               
                </v-card>
              </v-col>
          </v-row>
          <v-row class="hidden-md-and-up">
              <v-col>
                <v-card elevation="8" class="pt-4 pl-4">
                  <v-card-title class="charitytitle1">Post Graduate Course</v-card-title>           
                  <v-card-text>
                    <v-img                        
                        class="facilitiesimage mb-4"
                        alt="Facebook"                                
                        :src="photoChecker(image_filterer(items[3]))"
                        transition="scale-transition"     
                        width="100%"    
                        contain             
                    /> 
                    <!-- <v-img                        
                        class="facilitiesimage mb-4"
                        alt="Facebook"                                
                        :src="photoChecker(image_filterer(items[0]))"
                        transition="scale-transition"     
                        width="100%"    
                        contain             
                    />  -->
                    <!-- <v-img                        
                        class="facilitiesimage mb-4"
                        alt="Facebook"                                
                        :src="photoChecker(image_filterer(items[1]))"
                        transition="scale-transition"     
                        width="100%"    
                        contain             
                    />  -->
                    <!-- <div class="mb-4">
                        <iframe src="https://drive.google.com/file/d/1DvmykC3IVHPrnjWGUUo-2ioRyd-HKxIP/preview" width="100%" height="200" allow="autoplay"></iframe>               
                    </div>                   
                    <div class="mb-4">
                        <iframe src="https://drive.google.com/file/d/1OOqLCmb34wSlaeLFJmFOjqeuJtQmVoXh/preview" width="100%" height="200" allow="autoplay"></iframe>    
                    </div>    
                    <div class="mb-4">
                        <iframe src="https://drive.google.com/file/d/13nSYJCQLTMrGn76STrQiFc0haUwiV0Wd/preview" width="100%" height="200" allow="autoplay"></iframe>                    
                    </div>                      -->
                    <v-img                        
                        class="facilitiesimage mb-4"
                        alt="Facebook"                                
                        :src="photoChecker(image_filterer(items[2]))"
                        transition="scale-transition"     
                        width="100%"    
                        contain             
                    /> 
                    
                  </v-card-text>            
                </v-card>
              </v-col>
          </v-row>
      <!-- </div> -->
  </v-container>
</template>

<script>
import Pages from "../apis/Pages";
import { mapGetters } from "vuex";

  export default {
    data () {
      return {        
        filtered: [],
        photo: '/assets/images/facilities_sample.png',
      }
    },

    computed: {
      ...mapGetters({
        items: "getItems",
        // queue_clinic: "getQueueClinic", 
        // queue_service: "getQueueService",
      }),
    },

    created () {                
        this.getPageContent()
    },

    methods: {
      getPageContent() {
        Pages.get_page('post_grad')
        .then(response => {                
                this.$store.commit('ITEMS', response.data.components_attributes);   
                this.filterer(response.data.components_attributes)
                // this.items =  response.data            
                // console.log('test ', response.data.components_attributes)
            })
            .catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                }
                else if (error.response.status === 404) {
                    this.errors = error.response.data.errors;
                    this.$store.commit('ITEMS', []);   
                }
            });
      },      

      photoChecker(photo) {
        if(photo == '' || photo == null) {
          return this.photo
        }
        else {
          return photo
        }
      },

      image_filterer(payload) {
          var filtered_images = []
          filtered_images.push(payload.parts_attributes.filter(key => (key.type_of == 'Image')))
          if(filtered_images[0] == '') {
            return  ''        
          }
          else {
            return filtered_images[0][0].value
          }          
       },

    //   filterer(payload) {
    //     // console.log('payload',payload);
    //     // var filtered = []
    //     for(const p in payload) {
    //         // console.log(payload[p].parts_attributes);
    //         this.filtered.push(payload[p].parts_attributes
    //         .filter(key => (key.type_of == 'Image'))
            
    //         )
    //     }
    //     // console.log(this.filtered);
    //    } ,
    },
  }
</script>

<style scoped>
.charitytitle {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 32px;
    /* identical to box height, or 160% */
    letter-spacing: 0.25px;
    color: #0D09F0;
}
.charitytitle1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 32px;
    /* identical to box height, or 160% */
    letter-spacing: 0.25px;
    color: #0D09F0;    
}
</style>