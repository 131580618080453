<template>
   <!-- <v-app >
      <v-main> -->
         <v-container fluid fill-height>
            <v-layout align-center justify-center>
               <v-flex xs12 sm8 md4>
                  <v-card class="elevation-12">
                     <v-toolbar dark color="#2E47CB">
                        <v-toolbar-title>{{isRegister ? stateObj.register.name : stateObj.login.name}}</v-toolbar-title>
                     </v-toolbar>
                     <v-card-text>
                     <form ref="form" @submit.prevent="isRegister ? register() : login()">
                            <v-text-field
                              v-model="loginForm.username"
                              name="username"
                              label="Username"
                              type="text"
                              placeholder="Username"
                              required
                              color="#2E47CB"
                           ></v-text-field>
                           
                            <v-text-field
                              v-model="loginForm.password"
                              name="password"
                              label="Password"
                              type="password"
                              placeholder="Password"
                              required
                              color="#2E47CB"
                           ></v-text-field>

                           <v-text-field v-if="isRegister"
                              v-model="confirmPassword"
                              name="confirmPassword"
                              label="Confirm Password"
                              type="password"
                              placeholder="cocnfirm password"
                              required
                              color="#2E47CB"
                           ></v-text-field>
                           <!-- <div class="red--text"> {{errorMessage}}</div> -->
                           <v-alert
                           type="error"
                           v-if ="errorMessage != ''"
                           >{{errorMessage}}</v-alert>
                           <!-- <v-spacer></v-spacer> -->
                           
                           <!-- <div class="grey--text mt-4" v-on:click="isRegister = !isRegister;">
                              {{toggleMessage}}  
                           </div> -->
                           <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn block dark type="submit" color="#2E47CB" value="log in">{{isRegister ? stateObjButton.register.name : stateObjButton.login.name}}</v-btn>
                           </v-card-actions>
                      </form>
                     </v-card-text>
                     
                  </v-card>
                
               </v-flex>
            </v-layout>
         </v-container>
      <!-- </v-main>
   </v-app> -->
</template>

<script>
import Admin from "../apis/Admin";
// import { mapGetters } from "vuex";

export default {
  name: "LoginPage",
  data() {
    return {
      loginForm: {
         username: "",
         password: "",
      },
      confirmPassword: "",
      isRegister : false,
      errorMessage: "",
      stateObj: {
         register :{
            name: 'Register',
            message: 'Aleady have an Account? Login.'
         },
         login : {
            name: 'JRRMMC | Department of Orthopedics Site Administrator',
            message: 'Register'
         }
      },
      stateObjButton: {
         register :{
            name: 'Register',
            message: 'Already have an Acoount? login.'
         },
         login : {
            name: 'Login',
            message: 'Register'
         }
      }
    };
  },
  methods: {
    login() {
      Admin.login(this.loginForm)
      .then(response => {
         // console.log(response)
         sessionStorage.setItem("token", response.data.token);    
         this.$router.push({ name: "Dashboard" });  
      })
      .catch(error => {
         if (error.response.status === 422) {
            this.errors = error.response.data.errors;                        
         }
         else if (error.response.status === 401) {
            this.errors = error.response.data.errors;             
            this.errorMessage = "Invalid credentials!"           
         }
      });

      // const { username } = this;
      // console.log(username + "logged in")
      // this.$router.push({ name: "Home" });
    },
    register() {
       if(this.password == this.confirmPassword){
          this.isRegister = false;
          this.errorMessage = "";
          this.$refs.form.reset();
       }
       else {
         this.errorMessage = "password did not match"
       }
    }
  },
      computed: {
       toggleMessage : function() { 
          return this.isRegister ? this.stateObj.register.message : this.stateObj.login.message }
    }
};
</script>