<template>
    <v-container fluid>
    <v-card v-for="(a, index) in items" :key="index" elevation="0" class="facilitiescontainer hidden-sm-and-down">
        <v-card-text>
          <v-row                   
            v-if="index%2"
            class="pt-8 px-16"
            align="center" justify="center"
          >
            <!-- <v-card class="vertical-center" elevation="0" height="300">
              <v-card-text> -->
                <v-col v-if="a.parts_attributes.length > 1" align="center" justify="center">    
                  <!-- <span>{{imgvid_checker(a)}}</span> -->
                  <v-img
                    v-if="imgvid_checker(a) == 'Image'"
                    class="facilitiesimage"
                    alt="Facebook"                                
                    :src="photoChecker(image_filterer(a))"
                    transition="scale-transition"     
                    width="500"                     
                    contain              
                  />     
                  <iframe   
                    v-if="imgvid_checker(a) == 'Video'"
                    :src="video_filterer(a)" 
                    width="80%" 
                    height="300" 
                    style="border:0;" 
                    allowfullscreen="" 
                    loading="lazy" 
                    referrerpolicy="no-referrer-when-downgrade"
                  >
                  </iframe>   
                </v-col>
                <v-col align="center" justify="center">           
                  <v-card elevation="0" v-if="a.name == 'Jose R. Reyes Memorial Medical Center – Department of Physical Medicine and Rehabilitation'" href="https://www.facebook.com/JRRMMCRehabDept" target="_blank">
                    <v-card-title class="facilitiestitle justify-center">
                      {{ a.name }}
                    </v-card-title>
                    <v-card-text class="facilitiescontent">
                      {{ content_filterer(a) }}
                    </v-card-text>     
                  </v-card>
                  <v-card elevation="0" v-else>
                    <v-card-title class="facilitiestitle justify-center">
                      {{ a.name }}
                    </v-card-title>
                    <v-card-text class="facilitiescontent">
                      {{ content_filterer(a) }}
                    </v-card-text>     
                  </v-card>                             
                </v-col>
              <!-- </v-card-text> -->
            <!-- </v-card> -->
          </v-row>          
          <v-row                   
            v-if="!(index%2)"
            class="pt-8 px-16"
            align="center" justify="center"
          >
            <!-- <v-card class="vertical-center" elevation="0" height="300">
              <v-card-text> -->
                <v-col align="center" justify="center">                
                  <v-card elevation="0" v-if="a.name == 'Jose R. Reyes Memorial Medical Center – Department of Physical Medicine and Rehabilitation'">
                    <v-card-title class="facilitiestitle justify-center">
                      {{ a.name }}
                    </v-card-title>
                    <v-card-text class="facilitiescontent">
                      {{ content_filterer(a) }}
                    </v-card-text>
                    <v-card-text class="facilitiescontent" >
                      <v-row>
                        <v-col>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">                              
                              <v-btn v-bind="attrs" v-on="on" fab href="https://www.facebook.com/JRRMMCRehabDept" target="_blank">
                                <v-icon color="primary" large>mdi-facebook</v-icon>
                              </v-btn>
                            </template>
                            <span>Visit Us</span>
                          </v-tooltip>                          
                        </v-col>
                        <v-col>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">                              
                              <v-btn v-bind="attrs" v-on="on" fab href="https://m.me/JRRMMCRehabDept" target="_blank">
                                <v-icon color="primary" large>mdi-facebook-messenger</v-icon>
                              </v-btn>
                            </template>
                            <span>Contact Us</span>
                          </v-tooltip>                                        
                        </v-col>
                      </v-row>                      
                    </v-card-text>                          
                  </v-card>
                  <v-card elevation="0" v-else>
                    <v-card-title class="facilitiestitle justify-center">
                      {{ a.name }}
                    </v-card-title>
                    <v-card-text class="facilitiescontent">
                      {{ content_filterer(a) }}
                    </v-card-text>                       
                  </v-card>                               
                </v-col>
                <v-col v-if="a.parts_attributes.length > 1" align="center" justify="center">     
                  <!-- <span>{{imgvid_checker(a)}}</span>      -->
                  <v-img
                    v-if="imgvid_checker(a) == 'Image'"
                    class="facilitiesimage"
                    alt="Facebook"                                
                    :src="photoChecker(image_filterer(a))"
                    transition="scale-transition"     
                    width="500"     
                    contain            
                  />     
                  <iframe   
                    v-if="imgvid_checker(a) == 'Video'"
                    :src="video_filterer(a)" 
                    width="80%" 
                    height="300" 
                    style="border:0;" 
                    allowfullscreen="" 
                    loading="lazy" 
                    referrerpolicy="no-referrer-when-downgrade"
                  >
                  </iframe>                                                     
                </v-col>
                
              <!-- </v-card-text> -->
            <!-- </v-card> -->
          </v-row> 
        </v-card-text>
    </v-card>    

    <v-card v-for="(a, index) in items" :key="index" elevation="0" class="facilitiescontainer hidden-md-and-up">
        <v-card-text>               
          <v-row align="center" justify="center">        
            <v-col align="center" justify="center">               
                  <v-card-title class="facilitiestitle1 justify-center">
                    {{ a.name }}
                  </v-card-title>
                  <v-card-text class="facilitiescontent1 text-center">
                    {{ content_filterer(a) }}
                  </v-card-text>                                
                </v-col>
          </v-row> 
          <v-row align="center" justify="center"                        
          >
            <!-- <v-card class="vertical-center" elevation="0" height="300">
              <v-card-text> -->
                <v-col v-if="a.parts_attributes.length > 1" align="center" justify="center">          
                  <v-img
                    v-if="imgvid_checker(a) == 'Image'"
                    class="facilitiesimage"
                    alt="Facebook"                                
                    :src="photoChecker(image_filterer(a))"
                    transition="scale-transition"     
                    height="300"    
                    contain             
                  />     
                  <iframe   
                    v-if="imgvid_checker(a) == 'Video'"
                    :src="video_filterer(a)" 
                    width="80%" 
                    height="300" 
                    style="border:0;" 
                    allowfullscreen="" 
                    loading="lazy" 
                    referrerpolicy="no-referrer-when-downgrade"
                  >
                  </iframe>                
                </v-col>                
              <!-- </v-card-text> -->
            <!-- </v-card> -->
          </v-row>              
        </v-card-text>
    </v-card>    

    </v-container>
</template>

<script>
import News from "../apis/News";
import Pages from "../apis/Pages";
import { mapGetters } from "vuex";

  export default {
    name: 'HelloWorld',

    data: () => ({      
      photo: '/assets/images/facilities_sample.png',
    }),

    computed: {
      ...mapGetters({
        banners: "getBanners", 
        articles: "getArticles", 
        items: "getItems",
        // queue_clinic: "getQueueClinic", 
        // queue_service: "getQueueService",
      }),
    },

    created () {        
        this.getNewsContent()
        this.getPageContent()
        // localStorage.setItem('prev_route',this.$router.currentRoute.path)
    },

    methods: {
      getPageContent() {
        Pages.get_page('facilities')
        .then(response => {                
                this.$store.commit('ITEMS', response.data.components_attributes);   
                this.imgvid_checker(response.data.components_attributes)
                // this.items =  response.data            
                // console.log('test ', response.data.components_attributes)
            })
            .catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                }
                else if (error.response.status === 404) {
                    this.errors = error.response.data.errors;
                    this.$store.commit('ITEMS', []);   
                }
            });
      },

      getNewsContent() {
        News.get_articles()
            .then(response => {                
                this.$store.commit('ARTICLES', response.data);   
                // this.items =  response.data            
                // console.log('test ', response.data)
            })
            .catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    // this.snackbar_text = "Unable to load clinics!"
                    // this.snackbar_color = 'red darken-2'
                    // this.snackbar = true
                }
                else if (error.response.status === 404) {
                    this.errors = error.response.data.errors;
                    this.$store.commit('ARTICLES', []);   
                    // this.items = []
                    // this.snackbar_text = "Unable to load clinics!"
                    // this.snackbar_color = 'red darken-2'
                    // this.snackbar = true
                }
            });
      },

      photoChecker(photo) {
        if(photo == '' || photo == null) {
          return this.photo
        }
        else {
          return photo
        }
      },

      imgvid_checker(payload) {
        // console.log(payload) 
        var filtered_imgvid = []
        // for(const p in payload) {
          // console.log('tessss',payload[p].parts_attributes.filter(key => (key.type_of == 'Video'))) 
          // filtered_imgvid.push(payload[p].parts_attributes.filter(key => (key.type_of == 'Video')))
          filtered_imgvid.push(payload.parts_attributes.filter(key => (key.type_of == 'Video')))
          // console.log(filtered_imgvid[0][0].type_of)
        // }
        // console.log('fltrd',filtered_imgvid[0] == '') 
        if(filtered_imgvid[0] == '') {
          return  'Image'        
        }
        else {
          return filtered_imgvid[0][0].type_of
        }
        // console.log(filtered_imgvid[8])
        // var filtered_imgvid = []
          // filtered_imgvid.push(payload.parts_attributes.filter(key => (key.type_of == 'Video')))
          
      },

      image_filterer(payload) {
          var filtered_images = []
          filtered_images.push(payload.parts_attributes.filter(key => (key.type_of == 'Image')))
          if(filtered_images[0] == '') {
            return  ''        
          }
          else {
            return filtered_images[0][0].value
          }          
       },

       video_filterer(payload) {
          var filtered_videos = []
          filtered_videos.push(payload.parts_attributes.filter(key => (key.type_of == 'Video')))
          if(filtered_videos[0] == '') {
            return  ''        
          }
          else {
            return filtered_videos[0][0].value
          }             
       },

       content_filterer(payload) {
          var filtered_content = []
          filtered_content.push(payload.parts_attributes.filter(key => (key.type_of == 'Content')))
          if(filtered_content[0] == '') {
            return  ''        
          }
          else {
            return filtered_content[0][0].value
          }             
       },
    },
  }
  
</script>

<style scoped>
.facilitiesimage {
    /* border: 1px solid #2E47CB;
    box-sizing: border-box;
    border-radius: 4px; */
    width: 80%;
}

.facilitiestitle {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    /* identical to box height, or 160% */
    letter-spacing: 0.25px;
    color: #0D09F0;
    word-break: break-word !important;
}
.facilitiescontent {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* or 157% */
    letter-spacing: 0.1px;
    /* black/0.87 */
    color:  black !important;
    word-break: break-word !important;
}

.facilitiestitle1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    /* line-height: 32px; */
    /* identical to box height, or 160% */
    letter-spacing: 0.25px;
    color: #0D09F0;
    word-break: break-word !important;
}
.facilitiescontent1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    /* line-height: 22px; */
    /* or 157% */
    letter-spacing: 0.1px;
    /* black/0.87 */
    color:  black !important;
    word-break: break-word !important;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.rower:nth-child(even) {background: #FFF}
.rower:nth-child(odd) {background: #FFF}
</style>