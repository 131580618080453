import Api from "./Api";

export default {
    get_articles() {
        return Api().get("/articles");
    },
    get_articles_limiter(limit) {
        return Api().get(`/articles?limit=`+limit);
    },
 
    post_article(payload) {
        return Api().post("/admin/articles", payload);
    },

    update_article(payload) {
        return Api().patch(`/admin/articles/` + payload.id, payload);
    },

    delete_article(payload) {
        return Api().delete(`/admin/articles/` + payload.id, payload);
    },
}