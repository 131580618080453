<template>
  <v-container fluid class="newscontainer">
      <div class="hidden-sm-and-down">
          <v-row class="pt-8 px-16">
              <v-col>
                <v-card v-for="(item, index) in articles" :key="index" elevation="8" class="pl-2 mb-8 py-2">
                  <v-row>
                    <v-col v-if="item.photo_url != ''" class="pa-0 newsimage">
                      <v-img                                                       
                      alt="News"
                      height="500"          
                      contain
                      :src="item.photo_url"
                      transition="scale-transition"                    
                      />
                    </v-col>
                    <v-col>
                      <v-card-title class="newstitle">
                        {{ item.title }}
                      </v-card-title>
                      <v-card-text class="newscontent">
                        <span v-html="item.content"></span>
                      </v-card-text>    
                    </v-col>                    
                  </v-row>                                  
                </v-card>                
              </v-col>
          </v-row>
          <br>
      </div>
      <div class="hidden-md-and-up">
          <v-row>
              <v-col>
                <v-card v-for="(item, index) in articles" :key="index" elevation="8" class="px-2 mb-8 py-2">
                  <v-row>
                    <v-col v-if="item.photo_url != ''" class="pa-0 newsimage">
                      <v-img                                                       
                      alt="News"
                      width="1000"              
                      contain
                      :src="item.photo_url"
                      transition="scale-transition"                    
                      />
                    </v-col>
                    <v-col>
                      <v-card-title class="newstitle">
                        {{ item.title }}
                      </v-card-title>
                      <v-card-text class="newscontent">
                        <span v-html="item.content"></span>
                      </v-card-text>  
                    </v-col>                    
                  </v-row>                                  
                </v-card>                
              </v-col>
          </v-row>
          <br>
      </div>
  </v-container>
</template>

<script>
import News from "../apis/News";
import { mapGetters } from "vuex";

// import moment from 'moment';

  export default {
    name: 'HelloWorld',      

    data () {
      return {
        items: [
        {
          url: '/assets/images/training_image.png',
          title: 'News Test 1',
          content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
        {
          url: '/assets/images/training_image.png',
          title: 'News Test 2',
          content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
        {
          url: '/assets/images/training_image.png',
          title: 'News Test 3',
          content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
      ],
      }
    },

    computed: {
      ...mapGetters({
        banners: "getBanners", 
        articles: "getArticles", 
        // items: "getItems", 
        // queue_clinic: "getQueueClinic", 
        // queue_service: "getQueueService",
      }),
    },

    created() {
      this.getNewsContent()
    },

    methods: {       
      getNewsContent() {
        News.get_articles()
            .then(response => {                
                this.$store.commit('ARTICLES', response.data);   
                // this.items =  response.data            
                // console.log('test ', response.data)
            })
            .catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    // this.snackbar_text = "Unable to load clinics!"
                    // this.snackbar_color = 'red darken-2'
                    // this.snackbar = true
                }
                else if (error.response.status === 404) {
                    this.errors = error.response.data.errors;
                    this.$store.commit('ARTICLES', []);   
                    // this.items = []
                    // this.snackbar_text = "Unable to load clinics!"
                    // this.snackbar_color = 'red darken-2'
                    // this.snackbar = true
                }
            });
      },
    }
  }
</script>

<style scoped>
.newscontainer {
  background: #E5E5E5;
}
.newsimage {
    border: 2px solid #000000;
    box-sizing: border-box;
    /* border-radius: 4px; */
    background: white;
    width: 500px;
}
.newstitle {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    /* identical to box height, or 160% */
    letter-spacing: 0.25px;
    color: #0D09F0;
    word-break: break-word;
}
.newscontent {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* or 157% */
    letter-spacing: 0.1px;
    /* black/0.87 */
    color:  black !important;
    word-break: break-word;
    text-align: justify;
}
.facilitiescontainer {
  height: 306px;
  position: relative;
  /* border: 3px solid green; */
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
</style>