<template>

  <v-app>
    <!-- <div class="hidden-sm-and-down"> -->
      <HeaderBar />
      <NavigationBar  v-if="$route.meta.header == 0"/>    
      <Admin v-if="$route.meta.header === 1"/>  
      <Dashboard v-if="$route.meta.header === 2"/>
      <FooterBar v-if="$route.meta.header == 0"/>
    <!-- </div> -->
    <!-- <div class="hidden-md-and-up">
      <HeaderBar />
      <NavigationBar  v-if="$route.meta.header == 0"/>    
      <Admin v-if="$route.meta.header === 1"/>  
      <Dashboard v-if="$route.meta.header === 2"/>
      <FooterBar v-if="$route.meta.header == 0"/>
    </div> -->    
  </v-app>
  
</template>
<script async defer src="https://maps.googleapis.com/maps/api/js?key=AIzaSyAJTe4thAMIV2tnm_fiAyR-FwFiP_wlqAY&callback=initMap"></script>
<script>
import HeaderBar from './components/Header';
import NavigationBar from './components/NavigationBar';
import Admin from './components/Admin';
import Dashboard from './components/Dashboard';
import FooterBar from './components/Footer';

export default {
  name: 'App',

  components: {
    HeaderBar,
    NavigationBar,
    Admin,
    Dashboard,
    FooterBar,
  },

  data: () => ({
    //
  }),

  mounted() {
    this.$store.commit("LOGIN", !!sessionStorage.getItem("token"));
    // window.addEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
};
</script>

<style scoped>
.headertitle {

  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
  /* identical to box height */

  text-transform: uppercase;

  color: #000000;
}

.headersubtitle {

  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height */

  text-transform: uppercase;

  color: #000000;
}
</style>