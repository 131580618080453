<template>
  <v-container fluid class="alumnicontainer">  
      <v-row class="pt-8 px-16 hidden-sm-and-down" align="center" justify="center">        
        <v-col cols="12" align="center" justify="center">
          <!-- <v-card height="302" elevation="0" class="alumniimage" > -->
            <iframe 
              src="https://drive.google.com/file/d/1vk0837YXt-9lcYOjyiBIW7c66FMVmBxv/preview" 
              width="100%" 
              height="800" 
              style="border:1; border-color:blue" 
              allowfullscreen="" 
              loading="lazy" 
              referrerpolicy="no-referrer-when-downgrade"
            >
            </iframe>   
          <!-- </v-card> -->
        </v-col>        
      </v-row>

      <v-row class="px-16 hidden-sm-and-down" align="center" justify="center">
        <v-col cols="4" align="center" justify="center">
          <v-card height="302" elevation="0" class="alumniimage" >
            <v-carousel hide-delimiters :show-arrows="false" continuous :cycle="true" height="300px">
              <v-carousel-item
                v-for="(item1, i1) in items1"
                :key="i1"
                :src="item1.value"
                reverse-transition="fade-transition"
                transition="fade-transition"                              
                height="200px"                
              >
              <!-- <v-row class="fill-height" align="center" justify="center">
                        <div class="display-3 black--text">{{ item1.value }}</div>
                      </v-row>                        -->
              </v-carousel-item>
            </v-carousel> 
          </v-card>        
        </v-col>
        <v-col cols="4" align="center" justify="center">
          <v-card height="302" elevation="0" class="alumniimage" >
            <v-carousel hide-delimiters :show-arrows="false" continuous :cycle="true" height="300px">
              <v-carousel-item
                v-for="(item2, i2) in items2"
                :key="i2"
                :src="item2.value"
                reverse-transition="fade-transition"
                transition="fade-transition"                              
                height="300px"                
              >
              </v-carousel-item>
            </v-carousel> 
          </v-card>        
        </v-col>
        <v-col cols="4" align="center" justify="center">
          <v-card height="302" elevation="0" class="alumniimage" >
            <v-carousel hide-delimiters :show-arrows="false" continuous :cycle="true" height="300px">
              <v-carousel-item
                v-for="(item3, i3) in items3"
                :key="i3"
                :src="item3.value"
                reverse-transition="fade-transition"
                transition="fade-transition"                              
                height="300px"                
              >
              </v-carousel-item>
            </v-carousel> 
          </v-card>        
        </v-col>
      </v-row>      

      <v-row class="hidden-md-and-up" align="center" justify="center">
        <v-col cols="12" align="center" justify="center">
          <iframe 
            src="https://drive.google.com/file/d/1vk0837YXt-9lcYOjyiBIW7c66FMVmBxv/preview" 
            width="100%" 
            height="200" 
            style="border:1; border-color:blue" 
            allowfullscreen="" 
            loading="lazy" 
            referrerpolicy="no-referrer-when-downgrade"
          >
          </iframe>   
          
          <v-card height="202" elevation="0" class="alumniimage mb-2" >
            <v-carousel hide-delimiters :show-arrows="false" continuous :cycle="true" height="200px">
              <v-carousel-item
                v-for="(item11, i11) in items1"
                :key="i11"
                :src="item11.value"
                reverse-transition="fade-transition"
                transition="fade-transition"                              
                height="200px"                
              >
              </v-carousel-item>
            </v-carousel> 
          </v-card>

          <v-card height="202" elevation="0" class="alumniimage mb-2" >
            <v-carousel hide-delimiters :show-arrows="false" continuous :cycle="true" height="200px">
              <v-carousel-item
                v-for="(item12, i12) in items2"
                :key="i12"
                :src="item12.value"
                reverse-transition="fade-transition"
                transition="fade-transition"                              
                height="200px"                
              >
              </v-carousel-item>
            </v-carousel> 
          </v-card>

          <v-card height="202" elevation="0" class="alumniimage mb-2" >
            <v-carousel hide-delimiters :show-arrows="false" continuous :cycle="true" height="200px">
              <v-carousel-item
                v-for="(item13, i13) in items3"
                :key="i13"
                :src="item13.value"
                reverse-transition="fade-transition"
                transition="fade-transition"                              
                height="200px"                
              >
              </v-carousel-item>
            </v-carousel> 
          </v-card>                  
        </v-col>        
      </v-row>

      <v-row class="px-16 mb-8 hidden-sm-and-down" align="center" justify="center">
        <v-col align="center" justify="center">
          <div class="breaker">
            <v-card class="alumnilist">      
            <v-card-text>
              <v-timeline>
                <v-timeline-item 
                  class="timit"
                  color="#0D09F0"
                  v-for="(a, aindex) in alumni"
                  :key="aindex"                  
                >
                <span class="yrtxt" slot="opposite">{{ a.year }}</span>  
                <v-row
                  align="center"
                  justify="center"
                  v-for="(am, amindex) in a.members"
                  :key="amindex"   
                >
                  <v-col align="center" justify="center">
                    <span class="nametxt">{{ am.name }}</span>
                  </v-col>
                </v-row>              
                </v-timeline-item>                
              </v-timeline>
            </v-card-text>      
          </v-card>  
          </div>        
        </v-col>   
      </v-row>

      <v-row class="hidden-md-and-up" align="center" justify="center">
        <v-col align="center" justify="center">
          <div>
            <v-card class="alumnilist1">      
            <v-card-text>
              <v-timeline>
                <v-timeline-item                   
                  color="#0D09F0"
                  v-for="(a1, aindex1) in alumni"
                  :key="aindex1"                  
                >
                <span class="yrtxt1" slot="opposite">{{ a1.year }}</span>  
                <v-row
                  v-for="(am1, amindex1) in a1.members"
                  :key="amindex1"   
                >
                  <v-col>
                    <span class="nametxt1">{{ am1.name }}</span>
                  </v-col>
                </v-row>              
                </v-timeline-item>                
              </v-timeline>
            </v-card-text>      
          </v-card>  
          </div>        
        </v-col>
      </v-row>    
  </v-container>
</template>

<script>
import Pages from "../apis/Pages";
import { mapGetters } from "vuex";

  export default {
    name: 'HelloWorld',

    data: () => ({
      items1: [
          // {
          //   value: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg',
          // },
          // {
          //   value: 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',
          // },
          // {
          //   value: 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',
          // },
          // {
          //   id: 4,
          //   value: 'https://cdn.vuetifyjs.com/images/carousel/planet.jpg',
          // },
        ],
      items2: [
          
          // {
          //   value: 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',
          // },
          // {
          //   value: 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',
          // },
          // // {
          // //   id: 4,
          // //   value: 'https://cdn.vuetifyjs.com/images/carousel/planet.jpg',
          // // },
          // {
          //   value: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg',
          // },
        ],
      items3: [
          
          // {
          //   value: 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',
          // },
          // // {
          // //   id: 4,
          // //   value: 'https://cdn.vuetifyjs.com/images/carousel/planet.jpg',
          // // },
          // {
          //   value: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg',
          // },
          // {
          //   value: 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',
          // },
        ],
      alumni: [
        {
          year: '1972',
          members: [
            {
              name: 'Daniel Bautista',
            },
          ]
        },
        {
          year: '1973',
          members: [
            {
              name: 'Victorino Sumagang',
            },
          ]
        },
        {
          year: '1974',
          members: [
            {
              name: 'Felix Gacias',
            },
          ]
        },
        {
          year: '1975',
          members: [
            {
              name: 'Jose Santiago',
            },
          ]
        },
        {
          year: '1983',
          members: [
            {
              name: 'Florante P. Calacal',
            },
            {
              name: 'Oscar Marcos',
            },       
          ]
        },
        {
          year: '1984',
          members: [
            {
              name: 'Enrique Collantes',
            },   
          ]
        },
        {
          year: '1985',
          members: [
            {
              name: 'Thaddeus Abad',
            },   
          ]
        },
        {
          year: '1986',
          members: [
            {
              name: 'Allan Bogo',
            }, 
            {
              name: 'Bonifacio Ronsayro',
            },   
          ]
        },
        {
          year: '1987',
          members: [
            {
              name: 'Manolo Olimpo',
            },   
          ]
        },
        {
          year: '1988',
          members: [
            {
              name: 'Ramon Villasis',
            },   
          ]
        },
        {
          year: '1989',
          members: [
            {
              name: 'Sergio Dalisay',
            },   
            {
              name: 'Arthur Camagay',
            },  
          ]
        },
        {
          year: '1991',
          members: [
            {
              name: 'Enrico De Jesus',
            },   
            {
              name: 'Virgilio Martin',
            },
            {
              name: 'Rossana Mira-Halum',
            },   
            {
              name: 'Leonilo Pineda',
            },   
          ]
        },
        {
          year: '1992',
          members: [
            {
              name: 'Reynaldo Ozaeta',
            },   
            {
              name: 'Cesar Monzon',
            },
            {
              name: 'Juan Alex Castillo',
            },   
          ]
        },
        {
          year: '1993',
          members: [
            {
              name: 'Gilbert Beltran',
            },   
            {
              name: 'Paulo Castro, Jr.',
            },
          ]
        },
        {
          year: '1994',
          members: [
            {
              name: 'Gerundio Ellacer',
            },   
            {
              name: 'Rommel Tiangco',
            },
            {
              name: 'Silverio Redoblado',
            },
          ]
        },
        {
          year: '1995',
          members: [
            {
              name: 'Romeo Delgado',
            },    
          ]
        },
        {
          year: '1996',
          members: [
            {
              name: 'Cresencio Gonzales, Jr.',
            },  
            {
              name: 'Erwin Guzman',
            },    
          ]
        },
        {
          year: '1997',
          members: [
            {
              name: 'Juan Alejandro Legaspi',
            },  
            {
              name: 'Philip Luis Palencia',
            },    
          ]
        },
        {
          year: '1998',
          members: [
            {
              name: 'Federico Maralit',
            },  
            {
              name: 'Hermenegildo Garcia',
            },    
          ]
        },
        {
          year: '1999',
          members: [
            {
              name: 'Orlando Serafico',
            },  
            {
              name: 'Alex Duque',
            },    
          ]
        },
        {
          year: '2000',
          members: [
            {
              name: 'Jose Marcelino Cayton',
            },  
            {
              name: 'Percival H. Pangilinan',
            },    
          ]
        },
        {
          year: '2001',
          members: [
            {
              name: 'Richard M. Trinidad',
            },  
            {
              name: 'Albert Cesar S. Faller, Jr.',
            },  
            {
              name: 'Michael R. Moriones',
            },   
          ]
        },
        {
          year: '2002',
          members: [
            {
              name: 'Oliver M. Atanacio',
            },  
            {
              name: 'Antonio R. Puti, Jr.',
            },  
            {
              name: 'Arcadio Jonathan N. De Castro, III',
            },   
          ]
        },
        {
          year: '2003',
          members: [
            {
              name: 'Angel A. Cayetano',
            },  
            {
              name: 'Wilbur B. Belleca',
            },  
            {
              name: 'Alvin J. Abueg',
            },   
          ]
        },
        {
          year: '2004',
          members: [
            {
              name: 'Erwin G. Borja',
            },  
            {
              name: 'Raymundo R. Quimpo',
            },  
            {
              name: 'Renato D. Lipana',
            },   
          ]
        },
        {
          year: '2005',
          members: [
            {
              name: 'William D. Chuasuan, Jr.',
            },  
            {
              name: 'Sherwin U, Ramos',
            },    
          ]
        },
        {
          year: '2006',
          members: [
            {
              name: 'Augelito L. Goco',
            },  
            {
              name: 'Niccolo S. Mamba',
            },  
            {
              name: 'Marco Paolo G. Lasam',
            },   
          ]
        },
        {
          year: '2007',
          members: [
            {
              name: 'Jofrey D. Ang',
            },  
            {
              name: 'Alvin N. Enriquez',
            },    
          ]
        },
        {
          year: '2008',
          members: [
            {
              name: 'Arian I. Gozon',
            },         
          ]
        },
        {
          year: '2009',
          members: [
            {
              name: 'Dextre Calacal',
            },  
            {
              name: 'Jomello Christian G. Mercado',
            },    
          ]
        },
        {
          year: '2010',
          members: [
            {
              name: 'Jim Bryan P. Pantas',
            },  
            {
              name: 'Wenjames D. Laureta',
            },   
            {
              name: 'John Conrado S. Mataga, II',
            },  
            {
              name: 'Glicerio Lorenzo P. Zamora, III',
            },     
          ]
        },
        {
          year: '2011',
          members: [
            {
              name: 'Stephen B. Tabladillo',
            },         
          ]
        },
        {
          year: '2012',
          members: [
            {
              name: 'Frederick L. Iglesia',
            },
            {
              name: 'Ryan Ruiz',
            },   
            {
              name: 'Ronald Paulos Hibono',
            },            
          ]
        },
        {
          year: '2013',
          members: [
            {
              name: 'Dexter Antonio ✝' ,
            },
            {
              name: 'Buford Boado',
            },   
            {
              name: 'Manoj Kumar Shah',
            },            
          ]
        },
        {
          year: '2014',
          members: [
            {
              name: 'Ver Emerald Arco',
            },
            {
              name: 'Jonathan Sabadao',
            },   
            {
              name: 'Ryan Dela Rosa',
            },  
            {
              name: 'Pemba Lama',
            },            
          ]
        },
        {
          year: '2015',
          members: [
            {
              name: 'Cosette Esmeralda Atutubo',
            },         
          ]
        },
        {
          year: '2016',
          members: [
            {
              name: 'Alwen Arong',
            },         
          ]
        },
        {
          year: '2017',
          members: [
            {
              name: 'John Paulo Sison',
            },
            {
              name: 'Daveric De Jesus',
            },   
            {
              name: 'Mark Anthony Arias',
            },            
          ]
        },
        {
          year: '2018',
          members: [
            {
              name: 'Wesson Pious Espiritu',
            },
            {
              name: 'Rubie Anne E. Espinosa',
            },            
          ]
        },
        {
          year: '2019',
          members: [
            {
              name: 'Richard Christopher M. Lao',
            },
            {
              name: 'Edward T. Woo',
            },            
          ]
        },
        {
          year: '2020',
          members: [
            {
              name: 'Bernard Joseph L. Adajar',
            },
            {
              name: 'Marlon Rommel P. Narbarte',
            },   
            {
              name: 'Kenneth Mann W. Ong',
            },  
            {
              name: 'Charles Andrew R. Reyes',
            },            
          ]
        },
        {
          year: '2021',
          members: [
            {
              name: 'Derrick John L. Cabais',
            },
            {
              name: 'Maria Pamela Ann J. Cayanan',
            },   
            {
              name: 'Earl Kevin A. Hermoso',
            },  
            {
              name: 'Ervan Thierry A. Tan',
            },            
          ]
        },
        {
          year: '2022',
          members: [
            {
              name: 'Adrian Joseph C. Tablante',
            },
            {
              name: 'Moonyen Almira A. Panganiban',
            },   
            {
              name: 'Paolo M. Ramirez',
            },                    
          ]
        },
      ]
    }),

    computed: {
      ...mapGetters({
        items: "getItems",
        // queue_clinic: "getQueueClinic", 
        // queue_service: "getQueueService",
      }),
    },

    created () {                
        this.getPageContent()
    },

    methods: {
      getPageContent() {
        Pages.get_page('alumni')
        .then(response => {                
                // this.$store.commit('ITEMS', response.data.components_attributes);   
                this.items1 =  response.data.components_attributes[0].parts_attributes    
                this.items2 =  response.data.components_attributes[1].parts_attributes  
                this.items3 =  response.data.components_attributes[2].parts_attributes 
                // console.log('items1 ', response.data.components_attributes[0].parts_attributes )
            })
            .catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                }
                else if (error.response.status === 404) {
                  this.errors = error.response.data.errors;
                  this.items1 =  []   
                  this.items2 =  []   
                  this.items3 =  []   
                }
            });
      },      

      photoChecker(photo) {
        if(photo == '' || photo == null) {
          return this.photo
        }
        else {
          return photo
        }
      },
    },
  }
</script>

<style scoped>
.alumnicontainer {
  background: #E5E5E5;
}
.alumniimage {
    border: 1px solid #0D09F0 !important;
    box-sizing: border-box;
}

.alumnilist {
    /* position: absolute; */
    width: 620px;
    /* height: 1500px; */
    /* left: 200px;
    top: 631px; */

    /* background: #FFFFFF; */
    border: 1px solid #0D09F0;
    box-sizing: border-box;
}

.alumnilist1 {
    /* position: absolute; */
    /* width: 700px; */
    /* height: 1500px; */
    /* left: 200px;
    top: 631px; */

    /* background: #FFFFFF; */
    border: 1px solid #0D09F0;
    box-sizing: border-box;
}

.breaker {
  column-count: 3;
  column-gap: 30px;
  column-fill: balance;
  -webkit-column-break-inside: avoid; 
  /* height: 700px; */
  /* column-rule: 1px solid lightblue; */
  /* border: 1px solid #0D09F0;
  box-sizing: border-box; */
}

.yrtxt {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  /* font-size: calc(0.75vw + 0.75vh + 0.75vmin); */
  /* line-height: 32px; */
  /* identical to box height, or 80% */

  letter-spacing: 0.25px;

  color: red;
}

.nametxt {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;  
  /* font-size: calc(0.5vw + 0.5vh + 0.5vmin); */
  /* line-height: 32px; */
  /* identical to box height, or 80% */

  letter-spacing: 0.25px;

  color: black;
}

.yrtxt1 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  /* font-size: 1.5rem; */
  font-size: calc(1.25vw + 1.25vh + 1.25vmin);
  /* line-height: 32px; */
  /* identical to box height, or 80% */

  letter-spacing: 0.25px;

  color: red;
}

.nametxt1 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  /* font-size: 1rem;   */
  font-size: calc(0.75vw + 0.75vh + 0.75vmin);
  /* line-height: 32px; */
  /* identical to box height, or 80% */

  letter-spacing: 0.25px;

  color: black;
}

.timit {
  padding: 20px;
}
</style>