import axios from "axios";

let BaseApi = axios.create({
    // baseURL: "http://127.0.0.1:3000"
    // baseURL: "http://172.25.67.179:8001/api"
    // baseURL: "https://cors-anywhere.herokuapp.com/https://globber-api.herokuapp.com/pages",   
    baseURL: process.env.VUE_APP_API_URL,  
    // baseURL: "http://globber-production.ap-southeast-1.elasticbeanstalk.com",  
});

let Api = function() {
    let token = sessionStorage.getItem("token");
    // BaseApi.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

    if (token) {
      BaseApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;    

    }
  
    return BaseApi;
  };
  
  export default Api;
  
