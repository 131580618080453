<template>
  <v-container fluid>
      <div>
          <v-row class="pt-8 px-16 hidden-sm-and-down">
              <v-col>
                <v-card elevation="8" class="py-4 px-4">
                  <v-card-title class="charitytitle justify-center">JOSE R. REYES MEMORIAL MEDICAL CENTER</v-card-title>        
                  <v-card-title class="charitytext justify-center">Address: Rizal Avenue, San Lazaro Compound Sta. Cruz, Manila, Philippines</v-card-title>
                  <v-card-title class="charitytext justify-center">Tel.#: (02) 8711 9491 local 221 | (02) 8244 4700</v-card-title>
                  <v-card-title class="charitytext justify-center">E-Mail: orthodocsjr@yahoo.com | jrrmmcortho@gmail.com | secretariat@jrortho.com.ph</v-card-title>  
                  <v-card-text>
                    <!-- <v-carousel height="1000px">
                      <v-carousel-item
                        v-for="(item,i) in items"
                        :key="i"
                        :src="item.src"
                        reverse-transition="fade-transition"
                        transition="fade-transition"      
                                          
                      ></v-carousel-item>
                    </v-carousel> -->
                    <!-- :center="{lat:14.614617987004486, lng:120.98315782194014}" -->
                    <!-- <GmapMap                    
                      :center="{lat:14.61443, lng:120.98222}"
                      :zoom="20"
                      map-type-id="roadmap"
                      style="width: 100%; height: 50vw"
                    >
                      <GmapMarker
                        :key="index"
                        v-for="(m, index) in markers"
                        :position="m.position"
                        :clickable="true"
                        :draggable="true"
                        @click="center=m.position"
                      />
                    </GmapMap>    -->
                    <iframe 
                      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1327.967873138568!2d120.98240523596888!3d14.614119104116803!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x46d354adadb054d5!2sJose%20R.%20Reyes%20Memorial%20Medical%20Center!5e0!3m2!1sen!2sph!4v1650089630839!5m2!1sen!2sph" 
                      width="100%" 
                      height="600" 
                      style="border:2; border-color:black" 
                      allowfullscreen="" 
                      loading="lazy" 
                      referrerpolicy="no-referrer-when-downgrade"
                    >
                    </iframe>                
                  </v-card-text>               
                </v-card>
              </v-col>
          </v-row>
          <v-row class="hidden-md-and-up">
              <v-col>
                <v-card elevation="8" class="py-4 px-4">
                  <v-card-title class="charitytitle1 justify-center">JOSE R. REYES MEMORIAL MEDICAL CENTER</v-card-title>        
                  <v-card-title class="charitytext1 justify-center">Address: Rizal Avenue, San Lazaro Compound Sta. Cruz, Manila, Philippines</v-card-title>
                  <v-card-title class="charitytext1 justify-center">Tel.#: (02) 8711 9491 local 221 | (02) 8244 4700</v-card-title>
                  <v-card-title class="charitytext1 justify-center">E-Mail: orthodocsjr@yahoo.com | jrrmmcortho@gmail.com | secretariat@jrortho.com.ph</v-card-title>  
                  <v-card-text>
                    <!-- <v-carousel height="1000px">
                      <v-carousel-item
                        v-for="(item,i) in items"
                        :key="i"
                        :src="item.src"
                        reverse-transition="fade-transition"
                        transition="fade-transition"      
                                          
                      ></v-carousel-item>
                    </v-carousel> -->
                    <!-- :center="{lat:14.614617987004486, lng:120.98315782194014}" -->
                    <!-- <GmapMap                    
                      :center="{lat:14.61443, lng:120.98222}"
                      :zoom="20"
                      map-type-id="roadmap"
                      style="width: 100%; height: 50vw"
                    >
                      <GmapMarker
                        :key="index"
                        v-for="(m, index) in markers"
                        :position="m.position"
                        :clickable="true"
                        :draggable="true"
                        @click="center=m.position"
                      />
                    </GmapMap>   -->
                    <iframe 
                      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1327.967873138568!2d120.98240523596888!3d14.614119104116803!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x46d354adadb054d5!2sJose%20R.%20Reyes%20Memorial%20Medical%20Center!5e0!3m2!1sen!2sph!4v1650089630839!5m2!1sen!2sph" 
                      width="100%" 
                      height="600" 
                      style="border:2; border-color:black"
                      allowfullscreen="" 
                      loading="lazy" 
                      referrerpolicy="no-referrer-when-downgrade"
                    >
                    </iframe>   
                  </v-card-text>               
                </v-card>
              </v-col>
          </v-row>
      </div>
  </v-container>
</template>

<script>
  export default {
    name: 'ResearchPage',
    data () {
      return {
        items: [
          {
            src: 'https://images.pexels.com/photos/1462935/pexels-photo-1462935.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
          },
          {
            src: 'https://images.pexels.com/photos/6492953/pexels-photo-6492953.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
          },
          {
            src: 'https://images.pexels.com/photos/4593505/pexels-photo-4593505.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
          },
          {
            src: 'https://images.pexels.com/photos/4341403/pexels-photo-4341403.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
          },
        ],
      }
    },
  }
</script>

<style scoped>
.charitytitle {      
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 32px;
    /* identical to box height, or 160% */
    letter-spacing: 0.25px;
    color: #0D09F0;
}
.charitytext {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 32px;
    /* identical to box height, or 160% */
    letter-spacing: 0.25px;
    color: black;
    word-break: break-word;
}
.charitytitle1 {    
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 2rem;
    /* font-size: calc(2vw + 2vh + 1vmin); */
    /* line-height: 32px; */
    /* identical to box height, or 160% */
    letter-spacing: 0.25px;
    color: #0D09F0;
    
}
.charitytext1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    /* line-height: 32px; */
    /* identical to box height, or 160% */
    letter-spacing: 0.25px;
    color: black;
    word-break: break-word;
}

.v-card__title {
  word-break: break-word;
  text-align: center;
}
</style>