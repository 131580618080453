<template>
  <v-container fluid>
    <v-card height="820" elevation="6">
    <v-toolbar
      flat
      color="#2E47CB"
      dark
    >
      <v-toolbar-title>Admin Dashboard</v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- <v-btn
        color="yellow"    
        light            
        class="mb-2"
        @click="addPage()"
      >
        <v-icon class="mr-2">
          mdi-plus-circle
        </v-icon>
        Add Page
      </v-btn> -->
      <!-- <v-btn dark color="#2E47CB" @click="showAllUploads()" class="elevation-0">
        <v-icon dark x-large>
          mdi-attachment
        </v-icon>
      </v-btn> -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="#2E47CB"
            dark
            v-bind="attrs"
            v-on="on"
            @click="showAllUploads()"
            class="elevation-0"
          >
          <v-icon dark x-large>
            mdi-attachment
          </v-icon>
          </v-btn>
        </template>
        <span>Uploads</span>
      </v-tooltip>
    </v-toolbar>
    <v-tabs vertical
      v-model="tab"
      background-color="white"
      slider-color="red"
      grow
      height="app"
      @change="detectTab"
    >
      <v-tab
        v-for="page in pages"
        :key="page.tab"
      >
        {{ page.tab }}
      </v-tab>
      <v-tabs-items v-model="tab">
        <v-tab-item
          v-for="page in pages"
          :key="page.tab"
        >
          <v-card flat color="grey lighten-2" height="100%">      
            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="items"                
                class="elevation-1"
              >
                <template v-slot:top>
                  <v-toolbar
                    flat
                  >
                    <v-toolbar-title>{{ page.content }}</v-toolbar-title>
                    <v-divider
                      class="mx-4"
                      inset
                      vertical
                    ></v-divider>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue"
                      dark
                      class="mb-2"                
                      @click="addItem()"
                    >
                      <v-icon class="mr-2">
                        mdi-plus
                      </v-icon>
                      {{ formButtonTitle }}
                    </v-btn>
                    <!-- <v-spacer></v-spacer> -->

                    

                  </v-toolbar>
                </template>

                <template v-slot:[`item.content`]="{ item }">
                  <span v-html="item.content"></span>              
                </template>

                <template v-slot:[`item.photo_url`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon 
                        :color="photoColor(item.photo_url)" 
                        @click="photoToggle(item.photo_url)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ photoIcon(item.photo_url) }}
                      </v-icon> 
                    </template>
                    <span>View Photo</span>
                  </v-tooltip>                               
                </template>

                <template v-slot:[`item.displayed`]="{ item }">
                  <v-icon :color="displayColor(item.displayed)">
                    {{ displayIcon(item.displayed) }}
                  </v-icon>              
                </template>

                <template v-slot:[`item.published_at`]="{ item }">
                  {{ formatTimestamp(item.published_at) }}
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon   
                        color="blue"             
                        class="mr-2"
                        @click="editItem(item)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-pencil
                      </v-icon>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon   
                        color="red"                 
                        @click="deleteItem(item)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                  
                  
                </template>
                <template v-slot:no-data>
                  <v-btn
                    dark
                    color="blue"                    
                  >
                    Reset
                  </v-btn>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>

  
  </v-card>

  <v-dialog
    v-model="dialog"
    persistent
  >
    <!-- <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="blue"
        dark
        class="mb-2"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon class="mr-2">
          mdi-plus
        </v-icon>
        {{ formButtonTitle }}
      </v-btn>
    </template> -->
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ formTitle }}</span>                          
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-text-field
                v-model="editedItem.title"
                label="Title"
                outlined
              >
              </v-text-field>
              <vue-editor v-model="editedItem.content"></vue-editor>
              <!-- <v-textarea
                v-model="editedItem.content"
                label="Content"
                outlined
              ></v-textarea> -->
              <v-text-field
                class="mt-8"
                :prepend-icon="imageCheckerNews(editedItem.photo_url)"
                @click:prepend="photoToggle(editedItem.photo_url)"
                append-outer-icon="mdi-attachment"
                @click:append-outer="uploadsToggle(editedItem.photo_url, 2)"
                v-model="editedItem.photo_url"
                label="Photo"
                outlined
              >                
              </v-text-field>
              <!-- <v-text-field
                v-model="editedItem.published_at"
                label="Published At"
                outlined
              ></v-text-field> -->
              <!-- <v-text-field
                v-model="editedItem.displayed"
                label="Visible"
                outlined
              ></v-text-field> -->
              <v-switch
                v-model="editedItem.displayed"
                :label="`Displayed: ${editedItem.displayed.toString().toUpperCase()}`"
              ></v-switch>
            </v-col>                      
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          dark
          color="red darken-1"          
          @click="close"
        >
          Cancel
        </v-btn>
        <v-btn
          dark
          color="green darken-1"          
          @click="save"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog
    v-model="dialogPages"
    persistent
  >                     
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-text-field
                v-model="editedItemPages.name"
                label="Name"
                outlined
              >
              </v-text-field>
              <v-text-field
                v-model="editedItemPages.rank"
                type="number"
                label="Rank"
                outlined
              ></v-text-field>                               
            </v-col>                      
          </v-row>
          <v-row>
            <v-col>
              <v-data-table
                :headers="headersPartsAttributes"
                :items="editedItemPages.parts_attributes"                
                class="elevation-2"                
                :items-per-page="5"
              >
                <template v-slot:top>
                  <v-toolbar
                    flat
                  >
                    <v-toolbar-title>Parts</v-toolbar-title>
                    <v-divider
                      class="mx-4"
                      inset
                      vertical
                    ></v-divider>
                    <v-spacer></v-spacer>
                    <v-btn
                          color="primary"
                          dark
                          class="mb-2"                
                          @click="addParts()"
                        >
                          Add Parts
                        </v-btn>
                    <v-dialog
                      v-model="dialogPartsAttributes"
                      max-width="750px"
                      persistent
                    >
                      <v-card>
                        <v-card-title>
                          <span class="text-h5">{{ formTitlePartsAttributes }}</span>
                        </v-card-title>

                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col
                                cols="12"
                                sm="12"
                                md="12"
                              >
                                <!-- <v-text-field
                                  v-model="editedItemPartsAttributes.type_of"
                                  label="Type"
                                  outlined
                                  clearable
                                ></v-text-field> -->
                                <v-select
                                  color="#2E47CB"
                                  v-model="editedItemPartsAttributes.type_of"
                                  :items="type_selection"
                                  label="Type"
                                  outlined
                                ></v-select>
                              </v-col>                                                         
                            </v-row>
                            <v-row>
                              <v-col
                                cols="12"
                                sm="12"
                                md="12"
                              >
                                <!-- <v-text-field
                                  color="#2E47CB"
                                  v-model="editedItemPartsAttributes.value"
                                  label="Value"
                                  outlined
                                  clearable
                                > -->
                                <v-textarea 
                                  color="#2E47CB"                                     
                                  v-model="editedItemPartsAttributes.value"                                      
                                  :label="typeChecker(editedItemPartsAttributes.type_of)"
                                  outlined  
                                  clearable                  
                                >
                                  <template v-slot:prepend>
                                    <v-tooltip
                                      bottom
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-icon 
                                          @click="photoToggle(editedItemPartsAttributes.value)"
                                          v-on="on"
                                        >
                                          {{ imageChecker(editedItemPartsAttributes) }}
                                        </v-icon>
                                      </template>
                                      View Photo
                                    </v-tooltip>
                                  </template>

                                  <template v-slot:append-outer>
                                    <v-tooltip
                                      bottom
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-icon 
                                          @click="uploadsToggle(editedItemPartsAttributes, 1)"
                                          v-on="on"
                                        >
                                          {{ imageUploadChecker(editedItemPartsAttributes) }}
                                        </v-icon>
                                      </template>
                                      Upload File
                                    </v-tooltip>
                                  </template>
                                </v-textarea>
                              </v-col>   
                            </v-row>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="red darken-1"
                            dark                            
                            @click="closePartsAttributes"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            color="green darken-1"    
                            dark                        
                            @click="savePartsAttributes"
                          >
                            Save
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                      <v-card>
                        <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" dark @click="closeDelete">Cancel</v-btn>
                          <v-btn color="red darken-1" dark @click="deleteItemConfirm">OK</v-btn>
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.actions`]="{ item }">   
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="blue"                 
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                        @click="editItemPartsAttributes(item)"
                      >
                        mdi-pencil
                      </v-icon>
                    </template>
                    <span>Edit Part</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="red"                 
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                        @click="deleteItemPartsAttributes(item)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                    <span>Delete Part</span>
                  </v-tooltip>          
                </template>
                <template v-slot:no-data>
                  <v-btn
                    color="primary"
                    @click="initialize"
                  >
                    Reset
                  </v-btn>
                </template>
              </v-data-table>
              <!-- <v-row
                v-for="(p, index) in editedItemPages.parts_attributes" 
                :key="index"                
              >                
                <v-col>
                  <v-select
                    v-model="p.type_of"
                    :items="type_selection"
                    label="Type Of"
                    outlined
                  ></v-select>
                  <v-textarea                                      
                    v-model="p.value"                                      
                    :label="typeChecker(p.type_of)"
                    outlined                    
                  >
                     <template v-slot:prepend>
                      <v-tooltip
                        bottom
                      >
                        <template v-slot:activator="{ on }">
                          <v-icon 
                            @click="photoToggle(p.value)"
                            v-on="on"
                          >
                            {{ imageChecker(p) }}
                          </v-icon>
                        </template>
                        View Photo
                      </v-tooltip>
                    </template>

                    <template v-slot:append-outer>
                      <v-tooltip
                        bottom
                      >
                        <template v-slot:activator="{ on }">
                          <v-icon 
                            @click="uploadsToggle(p, 1)"
                            v-on="on"
                          >
                            {{ imageUploadChecker(p) }}
                          </v-icon>
                        </template>
                        Upload File
                      </v-tooltip>
                    </template>
                  </v-textarea>    
                </v-col>
                <v-col cols="1">
                  <v-btn
                    class="ml-1"
                    x-small
                    fab                                  
                    dark                                                 
                    @click="deletePart(index)"
                  >
                    <v-icon>
                      mdi-minus
                    </v-icon>                                  
                  </v-btn>
                </v-col>
              </v-row>                             -->
            </v-col>            
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          dark
          color="red darken-1"          
          @click="closePage"
        >
          Cancel
        </v-btn>
        <v-btn
          dark
          color="green darken-1"          
          @click="save"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>                   

  <v-dialog v-model="dialogDelete" persistent max-width="500px">
    <v-card>
      <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" dark @click="closeDelete">Cancel</v-btn>
        <v-btn color="blue darken-1" dark @click="deleteItemConfirm">OK</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="dialogAddPage" persistent max-width="500px">
    <v-card>
      <v-card-title class="pagedialog text-h5 justify-center">Create New Page</v-card-title>
      <v-divider></v-divider>
      <br>
      <v-card-text>
        <v-text-field
          color="#2E47CB"
          v-model="pageForm.name"
          label="Name of Page"
          outlined
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="dialogAddPage = !dialogAddPage">Cancel</v-btn>
        <v-btn color="blue darken-1" text @click="addNewPage">Add</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="photoDialog" max-width="750">
    <v-img          
      contain      
      max-width="750"
      :src="photoURL"
    ></v-img>
  </v-dialog>

  <v-dialog v-model="dialogDeletePart" persistent max-width="500px">
    <v-card>
      <v-card-title class="text-h5">Are you sure you want to delete this part?</v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" dark @click="closeDeletePart">Cancel</v-btn>
        <v-btn color="red darken-1" dark @click="deletePartConfirm">OK</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog
    v-model="dialogFaculty"
    persistent
  >                     
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <!-- <v-text-field
                v-model="editedItemFaculty.name"
                label="Name"
                outlined
              >
              </v-text-field> -->
              <v-select
                v-model="editedItemFaculty.name"
                :items="division_service"
                item-text="name"
                item-value="name"
                label="Name of Division/Service"
                outlined
              ></v-select>
              <!-- <v-text-field
                v-model="editedItemPages.rank"
                type="number"
                label="Rank"
                outlined
              ></v-text-field>                                -->
            </v-col>                      
          </v-row>
          <v-row>
            <v-col>
              <h3>MEMBERS 
                <v-btn
                  x-small
                  fab                                  
                  dark                                                 
                  @click="addMembers()"
                >
                  <v-icon>
                    mdi-plus
                  </v-icon>                                  
                </v-btn>                
              </h3>                                
              <br>
              <v-row
                v-for="(f, index) in editedItemFaculty.members_attributes" 
                :key="index"                
              >                
                <v-col>
                  <v-text-field
                    v-model="f.name"                                      
                    label="Name"
                    outlined
                  ></v-text-field>    
                  <v-text-field
                    v-model="f.info1"                                      
                    label="Image"
                    outlined
                  >
                    <template v-slot:prepend>
                      <v-tooltip
                        bottom
                      >
                        <template v-slot:activator="{ on }">
                          <v-icon 
                            @click="photoToggle(f.info1)"
                            v-on="on"
                          >
                            {{ imageCheckerMember(f.info1) }}
                          </v-icon>
                        </template>
                        View Photo
                      </v-tooltip>
                    </template> 
                    <template v-slot:append-outer>
                      <v-tooltip
                        bottom
                      >
                        <template v-slot:activator="{ on }">
                          <v-icon 
                            @click="uploadsToggle(f,3)"
                            v-on="on"
                          >
                            mdi-attachment
                          </v-icon>
                        </template>
                        Upload File
                      </v-tooltip>
                    </template>              
                  </v-text-field>  
                  <v-text-field
                    v-model="f.info2"                                      
                    label="Other Information"
                    outlined
                  ></v-text-field>  
                  <v-text-field
                    v-model="f.info3"                                      
                    label="Other Information"
                    outlined
                  ></v-text-field>    
                </v-col>
                <v-col cols="1">
                  <v-btn
                    class="ml-1"
                    x-small
                    fab                                  
                    dark                                                 
                    @click="deleteMember(index)"
                  >
                    <v-icon>
                      mdi-minus
                    </v-icon>                                  
                  </v-btn>
                </v-col>
              </v-row>                            
            </v-col>
            
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          dark
          color="red darken-1"          
          @click="closeFacultyPage"
        >
          Cancel
        </v-btn>
        <v-btn
          dark
          color="green darken-1"          
          @click="save"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog> 

  <v-dialog v-model="dialogDeleteMember" persistent max-width="500px">
    <v-card>
      <v-card-title class="text-h5">Are you sure you want to delete this member?</v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" dark @click="closeDeleteMember">Cancel</v-btn>
        <v-btn color="red darken-1" dark @click="deleteMemberConfirm">OK</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog     
    v-model="uploadsDialog" 
    max-width="750px" 
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    >
    <v-card>
      <v-toolbar
          dark
          color="#2E47CB"
        >
          <v-btn
            icon
            dark
            @click="uploadsDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>File Manager</v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- <v-toolbar-items>
            <v-btn
              dark
              text
              @click="uploadsDialog = false"
            >
              Save
            </v-btn>
          </v-toolbar-items> -->
        </v-toolbar>
      <v-row>
        <v-col cols='7'>
          <v-container class="mx-2 my-8">
            <v-data-table
              :headers="headersUploads"
              :items="uploads"
              sort-by="name"
              class="elevation-1"
              :search="searchUploads"      
            >
              <template v-slot:top>
                <v-toolbar
                  flat
                >
                  <v-toolbar-title>My Files</v-toolbar-title>
                  <v-divider
                    class="mx-4"
                    inset
                    vertical
                  ></v-divider>
                  <v-text-field
                    color="#2E47CB"
                    v-model="searchUploads"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                    clearable
                  ></v-text-field>
                  <v-spacer></v-spacer>
                  <v-dialog
                    v-model="dialogUpload"
                    max-width="500px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        dark
                        class="mb-2"
                        v-bind="attrs"
                        v-on="on"
                      >
                        New File
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="text-h5">Upload a File</span>
                      </v-card-title>

                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col
                              cols="12"
                              sm="12"
                              md="12"
                            >
                              <v-text-field
                                color="#2E47CB"
                                v-model="uploadForm.name"
                                label="Filename"
                                outlined
                              ></v-text-field>
                              <input type="file" @change="uploadFile" ref="file" id="file_input">
                              <!-- @change="processFile($event)" -->
                            </v-col>                     
                          </v-row>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          dark
                          @click="closeUpload"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          color="green darken-1"
                          dark
                          @click="saveUpload"
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialogDeleteUpload" max-width="500px">
                    <v-card>
                      <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" dark @click="closeDeleteUpload">Cancel</v-btn>
                        <v-btn color="red darken-1" dark @click="deleteItemConfirmUpload">OK</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
              <template v-slot:[`item.actions`]="{ item }">   
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="green"                       
                      class="mr-2"
                      v-bind="attrs"
                      v-on="on"
                      @click="viewItemUpload(item)"
                    >
                      mdi-eye
                    </v-icon>
                  </template>
                  <span>View</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="blue"                       
                      class="mr-2"
                      v-bind="attrs"
                      v-on="on"
                      @click="selectItemUpload(item)"
                    >
                      mdi-check
                    </v-icon>
                  </template>
                  <span>Select</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="red"                       
                      class="mr-2"
                      v-bind="attrs"
                      v-on="on"
                      @click="deleteItemUpload(item)"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>Delete</span>
                </v-tooltip>                                    
              </template>
              <template v-slot:no-data>
                <v-btn
                  color="primary"
                  @click="initialize"
                >
                  Reset
                </v-btn>
              </template>
            </v-data-table>
          </v-container>
        </v-col>
        <v-col cols='5'>
          <v-container class="mx-2 my-8">
            <v-img
            alt="Uploads"              
            contain
            :src="selectedPhoto"
            transition="scale-transition"
            width="100%"
            height="650"
          />
          </v-container>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>  

  </v-container>
</template>

<script>
import Pages from "../apis/Pages";
import News from "../apis/News";
import Faculty from "../apis/Faculty";
import Uploads from "../apis/Uploads";
import { mapGetters } from "vuex";
import moment from 'moment';
import { VueEditor } from "vue2-editor";

  export default {
    name: 'DashboardPage',

    components: {
      VueEditor
    },

    data () {
      return {
        tab: null,
        pages: [
          { tab: 'Home', content: 'Home', slug: 'home' },
          { tab: 'Divisions and Services', content: 'Divisions and Services', slug: 'divisions' },
          { tab: 'Facilities', content: 'Facilities', slug: 'facilities' },
          { tab: 'Training', content: 'Training', slug: 'training' },
          { tab: 'Charity Services', content: 'Charity Services', slug: 'services' },
          { tab: 'Alumni', content: 'Alumni', slug: 'alumni' },
          { tab: 'Research', content: 'Research', slug: 'research' },
          { tab: 'News', content: 'Articles', slug: 'articles' }, 
          { tab: 'Faculty', content: 'Faculty', slug: 'teams' },                    
          { tab: 'Post Graduate Course', content: 'Post Graduate Course', slug: 'post_grad' },    
          // { tab: 'Pre-Residency', content: 'Pre-Residency', slug: 'pre-residency' },                      
        ],
        type_selection: ['Image', 'Content', 'Video', 'Link', 'Messenger'],
        dialog: false,
        dialogDelete: false,
        headers: [],
        headersPages: [
          {
            text: 'Name',
            align: 'start',            
            value: 'name',
          },
          // { text: 'Rank', value: 'rank' },
          // { text: 'Fat (g)', value: 'fat' },
          // { text: 'Carbs (g)', value: 'carbs' },
          // { text: 'Protein (g)', value: 'protein' },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        headersArticle: [
          {
            text: 'Title',
            align: 'start',
            sortable: false,
            value: 'title',
          },
          { text: 'Content', value: 'content' },
          { text: 'Photo', value: 'photo_url' },
          { text: 'Published At', value: 'published_at' },
          { text: 'Displayed?', value: 'displayed' },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        headersPartsAttributes: [
          // { text: 'ID', value: 'id'},
          { text: 'Type', value: 'type_of'},
          { text: 'Value', value: 'value'},
          { text: 'Actions', value: 'actions', sortable: false },
        ],

        // items: [],        
        editedIndex: -1,
        // editedIndexPages: -1,
        editedItem: {
          name: '',
          content: '',
          photo_url: '',
          published_at: new Date(),
          displayed: false,
        },
        defaultItem: {
          name: '',
          content: '',
          photo_url: '',
          published_at: new Date(),
          displayed: false,
        },
        path: '',
        photoDialog: false,
        photoURL: '',
        dialogAddPage: false,
        pageForm: {
          name: '',
        },
        dialogPages: false,
        editedItemPages: {
          name: '',
          rank: '',
          parts_attributes: [{
            id: '',
            type_of: '',
            value: '',
          }],
        },
        defaultItemPages: {
          name: '',
          rank: '',
          parts_attributes: [{
            id: '',
            type_of: '',
            value: '',
          }],
        }, 
        parts_attributes: {
          id: '',
          type_of: '',
          value: '',
        },
        default_parts_attributes: {
          id: '',
          type_of: '',
          value: '',
        },
        parts_count: 0,
        dialogDeletePart: false,
        indexPart: '',
        editedIndexPartsAttributes: -1,
        editedItemPartsAttributes: {
          type_of: '',
          value: '',
        },
        defaultItemPartsAttributes: {
          type_of: '',
          value: '',
        },
        dialogPartsAttributes: false,
        //-----FACULTY------
        headersFaculty: [
          {
            text: 'Name',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          // { text: 'Content', value: 'content' },
          // { text: 'Photo', value: 'photo_url' },
          // { text: 'Published At', value: 'published_at' },
          // { text: 'Displayed?', value: 'displayed' },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        
        // items: [],        
        editedIndexFaculty: -1,
        // editedIndexPages: -1,
        editedItemFaculty: {
          name: '',
          slug: '',
          members_attributes: [{
            name: '',
            info1: '',
            info2: '',
            info3: '',
          }],
        },
        defaultItemFaculty: {
          name: '',
          slug: '',
          members_attributes: [{
            name: '',
            info1: '',
            info2: '',
            info3: '',
          }],
        },
        members_attributes: {
          name: '',
          info1: '',
          info2: '',
          info3: '',
        },
        default_members_attributes: {
          name: '',
          info1: '',
          info2: '',
          info3: '',
        },
        //-----END OF FACULTY------
        dialogFaculty: false,
        division_service: [],
        member_attribute_selection: ['Name', 'Photo', 'Content', 'Link'],
        dialogDeleteMember: false,
        indexMember: '',
        uploadsDialog: false,
        dialogUpload: false,
        dialogDeleteUpload: false,
        headersUploads: [
          {
            text: 'Filename',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          { text: 'ID', value: 'id' },
          // { text: 'Photo', value: 'photo_url' },
          // { text: 'Published At', value: 'published_at' },
          // { text: 'Displayed?', value: 'displayed' },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        searchUploads: '',
        uploadForm: {
          name: '',
          file: '',
        },
        defaultUploadForm: {
          name: '',
          file: '',
        },
        p_temp: {
          id: '',
          type_of: '',
          value: '',
        },
        mem_temp: {
          name: '',
          info1: '',
          info2: '',
          info3: '',
        },
        editedIndexUpload: -1,
        uploader: 0,
        photo: '/assets/images/JRRMMC_Ortho_Logo.jpg',
        selectedPhoto: '/assets/images/JRRMMC_Ortho_Logo.jpg',
      }
    },

    computed: {
      ...mapGetters({
        banners: "getBanners", 
        articles: "getArticles", 
        items: "getItems", 
        uploads: "getUploads", 
        // queue_clinic: "getQueueClinic", 
        // queue_service: "getQueueService",
      }),

      formTitle () {
        if(this.editedIndex === -1) {
          if(this.tab == 0) {
            return 'New Banner'
          }
          else if(this.tab == 1) {
            return 'New Division'
          }
          else if(this.tab == 2) {
            return 'New Facility'
          }
          else if(this.tab == 3) {
            return 'New Training'
          }
          else if(this.tab == 4) {
            return 'New Charity Service'
          }
          else if(this.tab == 5) {
            return 'New Alumni'
          }
          else if(this.tab == 6) {
            return 'New Research'
          }
          else if(this.tab == 7) {
            return 'New Article'
          }
          else if(this.tab == 8) {
            return 'New Faculty'
          }
          else if(this.tab == 9) {
            return 'New Post Graduate Course'
          }
          else if(this.tab == 10) {
            return 'New Pre-Residency'
          }
          else {
            return 'New Item'
          }
        }
        else {
          if(this.tab == 0) {
            return 'Edit Banner'
          }
          else if(this.tab == 1) {
            return 'Edit Division'
          }
          else if(this.tab == 2) {
            return 'Edit Facility'
          }
          else if(this.tab == 3) {
            return 'Edit Training'
          }
          else if(this.tab == 4) {
            return 'Edit Charity Service'
          }
          else if(this.tab == 5) {
            return 'Edit Alumni'
          }
          else if(this.tab == 6) {
            return 'Edit Research'
          }
          else if(this.tab == 7) {
            return 'Edit Article'
          }
          else if(this.tab == 8) {
            return 'Edit Faculty'
          }
          else if(this.tab == 9) {
            return 'Edit Post Graduate Course'
          }
          else if(this.tab == 10) {
            return 'Edit Pre-Residency'
          }
          else {
            return 'Edit Item'
          }
        }
        // return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      },

      formButtonTitle () {        
        if(this.tab == 0) {
          return 'New Banner'
        }
        else if(this.tab == 1) {
          return 'New Division'
        }
        else if(this.tab == 2) {
          return 'New Facility'
        }
        else if(this.tab == 3) {
          return 'New Training'
        }
        else if(this.tab == 4) {
          return 'New Charity Service'
        }
        else if(this.tab == 5) {
          return 'New Alumni'
        }
        else if(this.tab == 6) {
          return 'New Research'
        }
        else if(this.tab == 7) {
          return 'New Article'
        }
        else if(this.tab == 8) {
          return 'New Faculty'
        }
        else if(this.tab == 9) {
          return 'New Post Graduate Course'
        }
        else if(this.tab == 10) {
          return 'New Pre-Residency'
        }
        else {
          return 'New Item'
        }       
      },

      formTitlePartsAttributes () {
        return this.editedIndexPartsAttributes === -1 ? 'New Parts' : 'Edit Parts'
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogPages (val) {
        val || this.close()
      },
      dialogFaculty (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
      dialogUpload (val) {
        val || this.closeUpload()
      },
      dialogDeleteUpload (val) {
        val || this.closeDeleteUpload()
      },
    },

    created () {        
        // this.getHomeContent()
    },

    methods: {            
      addPage() {
        this.pageForm.name = ''
        this.dialogAddPage = !this.dialogAddPage
      },

      addNewPage() {
        alert(this.pageForm.name)
      },

      //--------------------START OF HOME CRUD--------------------
      getPage(slug) {
        Pages.get_page(slug)
            .then(response => {                
                this.$store.commit('ITEMS', response.data.components_attributes);
                // console.log('home',response.data.components)
            })
            .catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    // this.snackbar_text = "Unable to load clinics!"
                    // this.snackbar_color = 'red darken-2'
                    // this.snackbar = true
                }
                else if (error.response.status === 404) {
                    this.errors = error.response.data.errors;
                    this.$store.commit('ITEMS', []);   
                    // this.items = []
                    // this.snackbar_text = "Unable to load clinics!"
                    // this.snackbar_color = 'red darken-2'
                    // this.snackbar = true
                }
            });
      },

      postPage(slug) {      
        Pages.post_page(slug, this.editedItemPages)
            .then(response => {                
                // this.$store.commit('ARTICLES', response.data.components[0].parts);                
                console.log(response)
                this.getPage(this.pages[this.tab].slug)
            })
            .catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    // this.snackbar_text = "Unable to load clinics!"
                    // this.snackbar_color = 'red darken-2'
                    // this.snackbar = true
                }
                else if (error.response.status === 404) {
                    this.errors = error.response.data.errors;
                    // this.snackbar_text = "Unable to load clinics!"
                    // this.snackbar_color = 'red darken-2'
                    // this.snackbar = true
                }
            });
      },

      updatePage(slug) {   
        // console.log('de',this.editedItemPages)
        Pages.update_page(slug, this.editedItemPages)
            .then(response => {                
                // this.$store.commit('ARTICLES', response.data.components[0].parts);                
                console.log(response)
                this.getPage(this.pages[this.tab].slug)
            })
            .catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    // this.snackbar_text = "Unable to load clinics!"
                    // this.snackbar_color = 'red darken-2'
                    // this.snackbar = true
                }
                else if (error.response.status === 404) {
                    this.errors = error.response.data.errors;
                    this.getPage(this.pages[this.tab].slug)
                    // this.snackbar_text = "Unable to load clinics!"
                    // this.snackbar_color = 'red darken-2'
                    // this.snackbar = true
                }
            });
      },

      deleteComponent() {   
        // console.log('de',this.editedItemPages)
        Pages.delete_component(this.editedItemPages)
            .then(response => {                
                // this.$store.commit('ARTICLES', response.data.components[0].parts);                
                console.log(response)
                this.getPage(this.pages[this.tab].slug)
            })
            .catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    // this.snackbar_text = "Unable to load clinics!"
                    // this.snackbar_color = 'red darken-2'
                    // this.snackbar = true
                }
                else if (error.response.status === 404) {
                    this.errors = error.response.data.errors;
                    this.getPage(this.pages[this.tab].slug)
                    // this.snackbar_text = "Unable to load clinics!"
                    // this.snackbar_color = 'red darken-2'
                    // this.snackbar = true
                }
            });
      },
      //--------------------END OF HOME CRUD--------------------

      //--------------------START OF ARTICLE CRUD--------------------
      getNewsContent() {
        News.get_articles()
            .then(response => {                
                this.$store.commit('ITEMS', response.data);   
                // this.items =  response.data            
                // console.log('test ', response.data)
            })
            .catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    // this.snackbar_text = "Unable to load clinics!"
                    // this.snackbar_color = 'red darken-2'
                    // this.snackbar = true
                }
                else if (error.response.status === 404) {
                    this.errors = error.response.data.errors;
                    this.$store.commit('ITEMS', []);   
                    // this.items = []
                    // this.snackbar_text = "Unable to load clinics!"
                    // this.snackbar_color = 'red darken-2'
                    // this.snackbar = true
                }
            });
      },
      
      postArticle() {      
        News.post_article(this.editedItem)
            .then(response => {                
                // this.$store.commit('ARTICLES', response.data.components[0].parts);                
                console.log(response)
                this.getNewsContent()
            })
            .catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    // this.snackbar_text = "Unable to load clinics!"
                    // this.snackbar_color = 'red darken-2'
                    // this.snackbar = true
                }
                else if (error.response.status === 404) {
                    this.errors = error.response.data.errors;
                    // this.snackbar_text = "Unable to load clinics!"
                    // this.snackbar_color = 'red darken-2'
                    // this.snackbar = true
                }
            });
      },

      updateArticle() {      
        News.update_article(this.editedItem)
            .then(response => {                
                // this.$store.commit('ARTICLES', response.data.components[0].parts);                
                console.log(response)
                this.getNewsContent()
            })
            .catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    // this.snackbar_text = "Unable to load clinics!"
                    // this.snackbar_color = 'red darken-2'
                    // this.snackbar = true
                }
                else if (error.response.status === 404) {
                    this.errors = error.response.data.errors;
                    // this.snackbar_text = "Unable to load clinics!"
                    // this.snackbar_color = 'red darken-2'
                    // this.snackbar = true
                }
            });
      },

      deleteArticle() {      
        News.delete_article(this.editedItem)
        .then(response => {                
            // this.$store.commit('ARTICLES', response.data.components[0].parts);                
            console.log(response)
            this.getNewsContent()
        })
        .catch(error => {
            if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                // this.snackbar_text = "Unable to load clinics!"
                // this.snackbar_color = 'red darken-2'
                // this.snackbar = true
            }
            else if (error.response.status === 404) {
                this.errors = error.response.data.errors;
                this.getNewsContent()
                // this.snackbar_text = "Unable to load clinics!"
                // this.snackbar_color = 'red darken-2'
                // this.snackbar = true
            }
        });
      },
      //--------------------END OF ARTICLE CRUD--------------------

      //--------------------START OF FACULTY CRUD--------------------
      getAllFaculty() {
        Faculty.get_all_faculty()
            .then(response => {                
                this.$store.commit('ITEMS', response.data);   
                this.getDivisionNames()
                // this.items =  response.data            
                // console.log('test ', response.data)
            })
            .catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    // this.snackbar_text = "Unable to load clinics!"
                    // this.snackbar_color = 'red darken-2'
                    // this.snackbar = true
                }
                else if (error.response.status === 404) {
                    this.errors = error.response.data.errors;
                    this.$store.commit('ITEMS', []);   
                    // this.items = []
                    // this.snackbar_text = "Unable to load clinics!"
                    // this.snackbar_color = 'red darken-2'
                    // this.snackbar = true
                }
            });
      },

      getDivisionNames() {
        Faculty.get_divisions('divisions')
            .then(response => {                
                // this.$store.commit('ITEMS', response.data.components_attributes);
                // console.log('home',response.data.components)
                this.division_service = response.data.components_attributes
            })
            .catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                }
                else if (error.response.status === 404) {
                    this.errors = error.response.data.errors;
                    this.division_service = []
                }
            });
      },
      
      postFaculty() {      
        this.editedItemFaculty.slug = this.editedItemFaculty.name
        Faculty.post_faculty(this.editedItemFaculty)
            .then(response => {                
                // this.$store.commit('ARTICLES', response.data.components[0].parts);                
                console.log(response)
                this.getAllFaculty()
            })
            .catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    // this.snackbar_text = "Unable to load clinics!"
                    // this.snackbar_color = 'red darken-2'
                    // this.snackbar = true
                }
                else if (error.response.status === 404) {
                    this.errors = error.response.data.errors;
                    // this.snackbar_text = "Unable to load clinics!"
                    // this.snackbar_color = 'red darken-2'
                    // this.snackbar = true
                }
            });
      },

      updateFaculty() {    
        this.editedItemFaculty.slug = this.editedItemFaculty.name  
        Faculty.update_faculty(this.editedItemFaculty)
            .then(response => {                
                // this.$store.commit('ARTICLES', response.data.components[0].parts);                
                console.log(response)
                this.getAllFaculty()
            })
            .catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    // this.snackbar_text = "Unable to load clinics!"
                    // this.snackbar_color = 'red darken-2'
                    // this.snackbar = true
                }
                else if (error.response.status === 404) {
                    this.errors = error.response.data.errors;
                    // this.snackbar_text = "Unable to load clinics!"
                    // this.snackbar_color = 'red darken-2'
                    // this.snackbar = true
                }
            });
      },

      deleteFaculty() {      
        Faculty.delete_faculty(this.editedItemFaculty)
        .then(response => {                
            // this.$store.commit('ARTICLES', response.data.components[0].parts);                
            console.log(response)
            this.getAllFaculty()
        })
        .catch(error => {
            if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                // this.snackbar_text = "Unable to load clinics!"
                // this.snackbar_color = 'red darken-2'
                // this.snackbar = true
            }
            else if (error.response.status === 404) {
                this.errors = error.response.data.errors;
                this.getNewsContent()
                // this.snackbar_text = "Unable to load clinics!"
                // this.snackbar_color = 'red darken-2'
                // this.snackbar = true
            }
        });
      },
      //--------------------END OF FACULTY CRUD--------------------

      detectTab() {
        // alert(this.tab)
        // this.headers = this.headersArticle
        if(this.tab == 0 || this.tab == 1 || this.tab == 2 || this.tab == 3 || this.tab == 4 || this.tab == 5 || this.tab == 6 || this.tab == 9 || this.tab == 10) {
          this.headers = this.headersPages
          this.getPage(this.pages[this.tab].slug)
        }
        else if(this.tab == 7) {
          this.headers = this.headersArticle
          this.getNewsContent()
        }
        else if(this.tab == 8) {
          this.headers = this.headersFaculty
          this.getAllFaculty()
        }
        else {
          this.$store.commit('ITEMS', []);   
          this.items = []
          this.headers = this.headersPages
        }
      },

      addItem() {
        // const propOwn = JSON.stringify(this.editedItemPages.parts) ;
        // console.log(propOwn); // 1
        // console.log(propOwn.length); // 1
        // alert(this.editedIndex)
        if(this.tab == 0 || this.tab == 1 || this.tab == 2 || this.tab == 3 || this.tab == 4 || this.tab == 5 || this.tab == 6 || this.tab == 9 || this.tab == 10) {   
          this.parts_count = 0
          this.editedIndex = -1   
          // console.log(this.editedIndexPages)     
          this.editedItemPages = Object.assign({}, this.defaultItemPages)
          // console.log(this.editedItemPages)
          this.editedItemPages.parts_attributes = [{}]
          this.dialogPages = true
        }
        else if(this.tab == 7) {
          this.editedIndex = -1 
          // console.log(this.editedIndex)
          this.editedItem = Object.assign({}, this.defaultItem)
          this.dialog = true
        }
        else if(this.tab == 8) {
          this.editedIndex = -1 
          // console.log(this.editedIndex)
          this.editedItemFaculty = Object.assign({}, this.defaultItemFaculty)
          this.dialogFaculty = true
        }
        else {
          this.editedItemPages = Object.assign({}, this.defaultItemPages)
          this.editedItem = Object.assign({}, this.defaultItem)          
        }
        
      },

      editItem (item) {
        
        // alert(JSON.stringify(this.editedIndex))   
        if(this.tab == 0 || this.tab == 1 || this.tab == 2 || this.tab == 3 || this.tab == 4 || this.tab == 5 || this.tab == 6 || this.tab == 9 || this.tab == 10) {
          // alert(item) 
          this.editedIndex = this.items.indexOf(item)            
          this.editedItemPages = Object.assign({}, item)
          this.parts_count = this.editedItemPages.parts_attributes.length
          this.dialogPages = true
        }
        else if(this.tab == 7) {
          this.editedIndex = this.items.indexOf(item)  
          // alert(this.editedIndex)  
          // console.log(this.editedIndex)
          this.editedItem = Object.assign({}, item)
          this.dialog = true
        }
        else if(this.tab == 8) {
          this.editedIndex = this.items.indexOf(item)  
          // alert(this.editedIndex)  
          // console.log(this.editedIndex)
          this.editedItemFaculty = Object.assign({}, item)
          this.dialogFaculty = true
        }
        else {
          alert(this.tab)
        }
        
      },

      deleteItem (item) {
        
        if(this.tab == 0 || this.tab == 1 || this.tab == 2 || this.tab == 3 || this.tab == 4 || this.tab == 5 || this.tab == 6 || this.tab == 9 || this.tab == 10) {   
          this.editedIndex = this.items.indexOf(item)     
          this.editedItemPages = Object.assign({}, item)
        }   
        else if(this.tab == 7) {
          this.editedIndex = this.items.indexOf(item)
          this.editedItem = Object.assign({}, item)
        }    
        else if(this.tab == 8) {
          this.editedIndex = this.items.indexOf(item)
          this.editedItemFaculty = Object.assign({}, item)
        }        
        this.dialogDelete = true
      },

      deleteItemConfirm () {      
        if(this.tab == 0 || this.tab == 1 || this.tab == 2 || this.tab == 3 || this.tab == 4 || this.tab == 5 || this.tab == 6 || this.tab == 9 || this.tab == 10) {
          // this.items.splice(this.editedIndex, 1)
          this.deleteComponent()
        }   
        else if(this.tab == 7) {
          this.deleteArticle()
        }  
        else if(this.tab == 8) {
          this.deleteFaculty()
        }        
        this.closeDelete()
      },

      close () {
        // this.dialog = false
        //   this.$nextTick(() => {
        //     this.editedItem = Object.assign({}, this.defaultItem)
        //     this.editedIndex = -1
        //   })
        if(this.tab == 0 || this.tab == 1 || this.tab == 2 || this.tab == 3 || this.tab == 4 || this.tab == 5 || this.tab == 6 || this.tab == 9 || this.tab == 10) {
          this.dialogPages = false
          this.$nextTick(() => {
            this.editedItemPages = Object.assign({}, this.defaultItemPages)
            this.editedIndex = -1
          })
        }
        else if(this.tab == 7) {
          this.dialog = false
          this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
          })
        }   
        else if(this.tab == 8) {
          this.dialogFaculty = false
          this.$nextTick(() => {
            this.editedItemFaculty = Object.assign({}, this.defaultItemFaculty)
            this.editedIndex = -1
          })
        }                   
      },

      closePage () {
        this.dialogPages = false
          this.$nextTick(() => {
            this.editedItemPages = Object.assign({}, this.defaultItemPages)
            this.editedIndex = -1
          })
        // if(this.tab == 0) {
        //   this.dialog = false
        //   this.$nextTick(() => {
        //     this.editedItem = Object.assign({}, this.defaultItem)
        //     this.editedIndex = -1
        //   })
        // }   
        // else if(this.tab == 7) {
        //   this.dialogPages = false
        //   this.$nextTick(() => {
        //     this.editedItemPages = Object.assign({}, this.defaultItemPages)
        //     this.editedIndexPages = -1
        //   })
        // }  
      },

      closeFacultyPage () {
        this.dialogFaculty = false
          this.$nextTick(() => {
            this.editedItemFaculty = Object.assign({}, this.defaultItemFaculty)
            this.editedIndex = -1
          })
        // if(this.tab == 0) {
        //   this.dialog = false
        //   this.$nextTick(() => {
        //     this.editedItem = Object.assign({}, this.defaultItem)
        //     this.editedIndex = -1
        //   })
        // }   
        // else if(this.tab == 7) {
        //   this.dialogPages = false
        //   this.$nextTick(() => {
        //     this.editedItemPages = Object.assign({}, this.defaultItemPages)
        //     this.editedIndexPages = -1
        //   })
        // }  
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closePartsAttributes() {
        this.dialogPartsAttributes = false
      },

      save () {
        if (this.editedIndex > -1) {        
          if(this.tab == 0 || this.tab == 1 || this.tab == 2 || this.tab == 3 || this.tab == 4 || this.tab == 5 || this.tab == 6 || this.tab == 9 || this.tab == 10) {
            Object.assign(this.items[this.editedIndex], this.editedItemPages)
            this.closePage()
            this.updatePage(this.pages[this.tab].slug)
          }   
          else if(this.tab == 7) {
            Object.assign(this.items[this.editedIndex], this.editedItem)
            this.updateArticle()
            this.close()
          }    
          else if(this.tab == 8) {
            Object.assign(this.items[this.editedIndex], this.editedItemFaculty)
            this.closeFacultyPage()
            this.updateFaculty()          
          }  
        } 
        else {          
          if(this.tab == 0 || this.tab == 1 || this.tab == 2 || this.tab == 3 || this.tab == 4 || this.tab == 5 || this.tab == 6 || this.tab == 9 || this.tab == 10) {
            this.items.push(this.editedItemPages)
            // alert(this.pages[this.tab].slug)
            this.postPage(this.pages[this.tab].slug)
            this.closePage()
          }   
          else if(this.tab == 7) {
            this.items.push(this.editedItem)
            this.postArticle()
            this.close()
          }   
          else if(this.tab == 8) {
            this.items.push(this.editedItem)
            this.postFaculty()
            this.closeFacultyPage()
          }                   
        }  
        // this.close()      
      },

      addParts() {
        // this.parts_attributes = Object.assign({}, this.default_parts_attributes)
        // this.editedItemPages.parts_attributes.push(this.parts_attributes)

        // this.parts_count += 1
        // console.log(this.parts_count)
        this.editedItemPartsAttributes = Object.assign({}, this.defaultItemPartsAttributes)
        this.editedIndexPartsAttributes = -1
        this.dialogPartsAttributes = true

      },

      savePartsAttributes() {
        if(this.editedIndexPartsAttributes > -1) {
          // console.log('edit0',this.editedItemPages)
          console.log('edit',this.editedItemPartsAttributes)
          // this.editedItemPages.parts_attributes.push(this.editedItemPartsAttributes)
          console.log('edit1',this.editedItemPages)

          // this.editedIndexPartsAttributes = this.editedItemPages.parts_attributes.indexOf(item)
          // this.editedItemPartsAttributes = Object.assign({}, item)

          Object.assign(this.editedItemPages.parts_attributes[this.editedIndexPartsAttributes], this.editedItemPartsAttributes)
          this.dialogPartsAttributes = false
        }
        else {
          // console.log('add0',this.editedItemPages)
          console.log('add',this.editedItemPartsAttributes)
          this.editedItemPages.parts_attributes.push(this.editedItemPartsAttributes)
          console.log('add1',this.editedItemPages)
          this.dialogPartsAttributes = false
        }
        
      },

      editItemPartsAttributes (item) {
        this.editedIndexPartsAttributes = this.editedItemPages.parts_attributes.indexOf(item)
        this.editedItemPartsAttributes = Object.assign({}, item)
        this.dialogPartsAttributes = true
      },

      addMembers() {
        this.members_attributes = Object.assign({}, this.default_members_attributes)
        this.editedItemFaculty.members_attributes.push(this.members_attributes)
        // this.parts_count += 1
        // console.log(this.parts_count)
      },

      deleteItemPartsAttributes(item) {
        this.editedIndexPartsAttributes = this.editedItemPages.parts_attributes.indexOf(item)
        this.indexPart = this.editedIndexPartsAttributes
        // this.editedItemPages.parts_attributes.splice(this.editedIndexPartsAttributes,1)
        // this.editedItemPartsAttributes = Object.assign({}, item)
        this.dialogDeletePart = true
      },

      deletePart(index) {
        if (this.editedIndex > -1) {  
          // console.log(payload.id)
          this.indexPart = index
          
          // console.log(this.editedItemPages.parts_attributes[index])
          // arr1.push({_destroy: true})
          
          // this.editedItemPages.parts_attributes.splice(index,1)
          this.dialogDeletePart = true
          // this.editedItemPages.parts_attributes.push(this.parts_attributes)        
        }
        else {
          this.editedItemPages.parts_attributes.splice(index,1)
          // this.parts_count -= 1
        }
        
      },

       deleteMember(index) {
        if (this.editedIndex > -1) {  
          // console.log(payload.id)
          this.indexMember = index
          
          // console.log(this.editedItemPages.parts_attributes[index])
          // arr1.push({_destroy: true})
          
          // this.editedItemPages.parts_attributes.splice(index,1)
          this.dialogDeleteMember = true
          // this.editedItemPages.parts_attributes.push(this.parts_attributes)        
        }
        else {
          this.editedItemFaculty.members_attributes.splice(index,1)
          // this.parts_count -= 1
        }
        
      },

      deletePartConfirm() {
        var arr1 = this.editedItemPages.parts_attributes[this.indexPart]
        arr1["_destroy"] = true;
        // console.log(arr1)
        // console.log(this.editedItemPages)
        // console.log(this.editedItemPages)
        Pages.delete_part(this.pages[this.tab].slug,this.editedItemPages)
        .then(response => {     
          this.dialogDeletePart = false           
          // this.editedItemPages.parts_attributes.splice(index,1)
          // console.log(response.data.components_attributes)
          this.editedItemPages = Object.assign({}, response.data.components_attributes[1])
        })
        .catch(error => {
            if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                // this.snackbar_text = "Unable to load clinics!"
                // this.snackbar_color = 'red darken-2'
                // this.snackbar = true
            }
            else if (error.response.status === 404) {
                this.errors = error.response.data.errors;
                this.dialogDeletePart = false  
                // this.editedItemPages.parts_attributes.splice(index,1)
                // this.snackbar_text = "Unable to load clinics!"
                // this.snackbar_color = 'red darken-2'
                // this.snackbar = true
            }
        });
      },

      deleteMemberConfirm() {
        var arr1 = this.editedItemFaculty.members_attributes[this.indexMember]
        arr1["_destroy"] = true;
        // console.log(arr1)
        // console.log(this.editedItemPages)
        // console.log(this.editedItemPages)
        Pages.delete_part(this.pages[this.tab].slug,this.editedItemFaculty)
        .then(response => {     
          this.dialogDeletePart = false           
          // this.editedItemPages.parts_attributes.splice(index,1)
          // console.log(response.data.components_attributes)
          this.editedItemPages = Object.assign({}, response.data.components_attributes[1])
        })
        .catch(error => {
            if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                // this.snackbar_text = "Unable to load clinics!"
                // this.snackbar_color = 'red darken-2'
                // this.snackbar = true
            }
            else if (error.response.status === 404) {
                this.errors = error.response.data.errors;
                this.dialogDeletePart = false  
                // this.editedItemPages.parts_attributes.splice(index,1)
                // this.snackbar_text = "Unable to load clinics!"
                // this.snackbar_color = 'red darken-2'
                // this.snackbar = true
            }
        });
      },

      closeDeletePart () {
        this.dialogDeletePart = false
        this.indexPart = ''        
      },

      closeDeleteMember () {
        this.dialogDeleteMember = false
        this.indexMember = ''        
      },

      deleteParts() {        
        var arr1 = this.editedItemPages.parts_attributes[this.parts_count-1]
        // arr1.push({_destroy: true})
        arr1["_destroy"] = true;
        console.log(arr1)
        console.log(this.editedItemPages)
        
      },

      photoIcon (payload) {
        if (payload != '') { return 'mdi-eye' }
        else  { return 'mdi-close-thick' }
      },

      photoColor (payload) {
        if (payload != '') { return 'green' }
        else  { return 'red' }
      },

      photoToggle (payload) {
        if (payload != '') { 
          this.photoDialog = true 
          this.photoURL = payload
        }
        else  { this.photoDialog = false }
      },

      displayIcon (payload) {
        if (payload != '') { return 'mdi-check-bold' }
        else  { return 'mdi-close-thick' }
      },

      displayColor (payload) {
        if (payload != '') { return 'green' }
        else  { return 'red' }
      },

      formatTimestamp(item) {
        return item ? moment(item).format('MMMM DD, YYYY | hh:mm:ss A') : ''
      },

      imageChecker(payload) {
        if(payload.type_of == 'Image') {
          if(payload.value == '' || payload.value == null ) {
            return ''
          }
          else {
            return 'mdi-eye'
          }
        }
        else {
          return ''
        }
      },

      imageCheckerNews(payload) {        
        if(payload == '' || payload == null ) {
          return ''
        }
        else {
          return 'mdi-eye'
        }            
      },

       imageCheckerMember(payload) {        
        if(payload == '' || payload == null ) {
          return ''
        }
        else {
          return 'mdi-eye'
        }            
      },

      imageUploadChecker(payload) {
        if(payload.type_of == 'Image' || payload.type_of == 'Video') {
            return 'mdi-attachment'
        }
        else {
          return ''
        }
      },

      typeChecker(type) {
        if(type == 'Image' || type == 'Video') {
          return 'File'
        }
        else {
          return 'Value'
        }
      },
      // Start of Upload Methods
      uploadsToggle(p, checker) {
        // alert(p + i)
        this.uploader = 0
        Uploads.get_uploads()
        .then(response => {                
            this.$store.commit('UPLOADS', response.data);
            // console.log('uploads',response.data)
            // this.division_service = response.data.components_attributes
            if(checker == 1) {
              this.p_temp = p
            }
            else if(checker == 2) {
              this.editedItem.photo_url = p
            }
            else if(checker == 3) {
              this.mem_temp = p
              // console.log('pp', p)
            }
            else {
              this.p_temp = p
            }

            this.uploader = checker
            this.uploadsDialog = true
        })
        .catch(error => {
            if (error.response.status === 422) {
                this.errors = error.response.data.errors;
            }
            else if (error.response.status === 404) {
                this.errors = error.response.data.errors;    
                this.$store.commit('UPLOADS', []);            
            }
        });        
        // p.value = 'test'
      },

      showAllUploads() {
        this.getUploads()
        this.uploadsDialog = true
      },

      getUploads() {
        Uploads.get_uploads()
        .then(response => {                
            this.$store.commit('UPLOADS', response.data);            
        })
        .catch(error => {
            if (error.response.status === 422) {
                this.errors = error.response.data.errors;
            }
            else if (error.response.status === 404) {
                this.errors = error.response.data.errors;
                this.$store.commit('UPLOADS', []);            
            }
        });    
      },

      selectItemUpload (item) {
        // this.editedIndex = this.desserts.indexOf(item)
        // this.editedItem = Object.assign({}, item)
        // console.log(item.file)
        // console.log(this.p_temp)
        if(this.uploader == 1) {
          this.p_temp.value = 'https://globber-jrortho.onrender.com/uploads/'+item.id
        }
        else if(this.uploader == 2) {
          this.editedItem.photo_url = 'https://globber-jrortho.onrender.com/uploads/'+item.id
        }
        else if(this.uploader == 3) {
          this.mem_temp.info1 = 'https://globber-jrortho.onrender.com/uploads/'+item.id
        }
        this.uploader = 0
        this.uploadsDialog = false
      },

      deleteItemUpload (item) {
        this.uploadForm = item
        // this.editedIndex = this.desserts.indexOf(item)
        // this.editedItem = Object.assign({}, item)
        this.dialogDeleteUpload = true
      },

      deleteItemConfirmUpload () {
        console.log(this.uploadForm)
        // this.desserts.splice(this.editedIndex, 1)
        Uploads.delete_upload(this.uploadForm)
        .then(() => { 
          this.getUploads()
          this.closeDeleteUpload()                     
        })
        .catch(error => {
            if (error.response.status === 422) {
                this.errors = error.response.data.errors;
            }
            else if (error.response.status === 404) {
                this.errors = error.response.data.errors;
                this.$store.commit('UPLOADS', []);            
            }
        });      
        
      },

      closeUpload () {
        document.getElementById('file_input').value= null;

        // this.$refs.file.files = new FileList('')
        this.dialogUpload = false
        this.$nextTick(() => {
          this.uploadForm = Object.assign({}, this.defaultUploadForm)
          this.editedIndexUpload = -1
        })
      },

      closeDeleteUpload () {
        // document.getElementById('file_input').value= null;
        this.dialogDeleteUpload = false
        this.$nextTick(() => {
          this.uploadForm = Object.assign({}, this.defaultUploadForm)
          this.editedIndexUpload = -1
        })
      },

      uploadFile() {
        this.uploadForm.file = this.$refs.file.files[0];
        this.uploadForm.name = this.$refs.file.files[0].name;
      },

      saveUpload () {
        // console.log(this.uploadForm.file)
        const formData = new FormData();
        formData.append('name',this.uploadForm.name);
        formData.append('file',this.uploadForm.file);
        Uploads.post_upload(formData)
        .then(() => {    
          this.getUploads()
          this.closeUpload()                                       
        })
        .catch(error => {
            if (error.response.status === 422) {
                this.errors = error.response.data.errors;
            }
            else if (error.response.status === 404) {
                this.errors = error.response.data.errors;
                this.$store.commit('UPLOADS', []);            
            }
        });   
        // if (this.editedIndex > -1) {
        //   Object.assign(this.desserts[this.editedIndex], this.editedItem)
        // } else {
        //   this.desserts.push(this.editedItem)
        // }      
      },

      viewItemUpload(photo) {
        console.log(photo.file)
        if(photo == '' || photo == null) {
          this.selectedPhoto =  this.photo
        }
        else {
          this.selectedPhoto = photo.file
        }
      },
      // End of Upload Methods    
    },    
  }
</script>

<style scoped>
.pagedialog {
  background: #2E47CB;
  color: white;
}
</style>