<template>
    <div id="app" class="d-flex align-center" @click="gotoHome()">
      <!-- <v-container> -->
        <v-img
          alt="JRRMMC"
          class="ml-16 logos hidden-sm-and-down"
          contain
          src="/assets/images/JRRMMC_Logo.jpg"
          transition="scale-transition"          
        />        
        <v-img
          alt="JRRMMC Orthopedics"    
          class="logos hidden-sm-and-down"      
          contain
          src="/assets/images/JRRMMC_Ortho_Logo.jpg"
          transition="scale-transition"          
        />

        <v-img
          alt="JRRMMC"
          class="ml-2 mt-4 logos1 hidden-md-and-up"
          contain
          src="/assets/images/JRRMMC_Logo.jpg"
          transition="scale-transition"          
        />        
        <v-img
          alt="JRRMMC Orthopedics"    
          class="mt-4 logos1 hidden-md-and-up"      
          contain
          src="/assets/images/JRRMMC_Ortho_Logo.jpg"
          transition="scale-transition"          
        />

        <v-list-item two-line class="hidden-sm-and-down">
          <v-list-item-content>
            <v-list-item-title class="headertitle"><b>DEPARTMENT OF ORTHOPAEDICS</b></v-list-item-title>
            <v-list-item-subtitle class="headersubtitle">Jose R. Reyes Memorial Medical Center</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line class="hidden-md-and-up">
          <v-list-item-content>
            <v-list-item-title class="mt-4 headertitle1"><b>DEPARTMENT OF ORTHOPAEDICS</b></v-list-item-title>
            <v-list-item-subtitle class="headersubtitle1">Jose R. Reyes Memorial Medical Center</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-spacer></v-spacer>
        <v-btn fab elevation="0" v-if="$route.meta.header == 2" @click="logout()">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="#2E47CB" large v-bind="attrs" v-on="on">
                mdi-logout
              </v-icon>
            </template>
            <span>Logout</span>
          </v-tooltip>         
        </v-btn>
                          
      </div>
    <!-- </v-container> -->
</template>

<script>
  export default {
    name: 'HeaderBar',

    data: () => ({

    }),

    methods: {
      logout() {
        sessionStorage.removeItem('token')
        this.$store.commit("LOGIN", false);
        this.$router.push( { name: "Home" } ) 
      },

      gotoHome() {
        this.$router.push( { name: "Home" } )        
      }
    }
  }
</script>

<style scoped>
.headertitle {

  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2rem;
  /* font-size: 30px; */
  /* font-size: 2vw; */
  /* line-height: 36px; */
  /* identical to box height */

  text-transform: uppercase;

  color: #000000;
}

.headersubtitle {

  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  /* font-size: 30px; */
  /* font-size: 1.25vw; */
  /* line-height: 24px; */
  /* identical to box height */

  text-transform: uppercase;

  color: #000000;
}

.headertitle1 {

  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: calc(1vh + 1vw);
  /* font-size: 30px; */
  /* font-size: 2vw; */
  /* line-height: 36px; */
  /* identical to box height */

  text-transform: uppercase;

  color: #000000;
}

.headersubtitle1 {

  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: calc(0.75vh + 0.75vw);
  /* font-size: 30px; */
  /* font-size: 1.25vw; */
  /* line-height: 24px; */
  /* identical to box height */

  text-transform: uppercase;

  color: #000000;
}

.v-image__image--contain {
  background-position: left center !important;
}

.logos {
    max-width: 100px;
    height: auto;
    width: auto\9; /* ie8 */
    padding-top: 10px;
    padding-bottom: 10px;
    
}

.logos1 {
    max-width: 50px;
    height: auto;
    width: auto\9; /* ie8 */
    
}
</style>