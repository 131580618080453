import Api from "./Api";

export default {
    get_uploads() {
        return Api().get("/admin/uploads");
    },
 
    post_upload(payload) {
        return Api().post("/admin/uploads", payload);
    },

    // update_article(payload) {
    //     return Api().patch(`/admin/articles/` + payload.id, payload);
    // },

    delete_upload(payload) {
        return Api().delete(`/admin/uploads/` + payload.id, payload);
    },
}