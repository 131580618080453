import Vue from "vue";
import Vuex from "vuex";
// import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex);

export default new Vuex.Store({
    // plugins: [createPersistedState()],
    state: {
        auth: {
            login: false,
            banners: [],
            articles: [],
            items: [],
            members: [],
            uploads: [],
        }
    },

    getters: {
        isLoggedIn(state) {
            return state.auth.login;
        },
        getBanners(state) {
            return state.auth.banners;
        },
        getArticles(state) {
            return state.auth.articles;
        },
        getItems(state) {
            return state.auth.items;
        },
        getMembers(state) {
            return state.auth.members;
        },
        getUploads(state) {
            return state.auth.uploads;
        },

    },

    mutations: {
        LOGIN(state, payload) {
            state.auth.login = payload;
        },        
        BANNERS(state, payload) {
            state.auth.banners = payload
        }, 
        ARTICLES(state, payload) {
            state.auth.articles = payload
        },   
        ITEMS(state, payload) {
            state.auth.items = payload
        },  
        MEMBERS(state, payload) {
            state.auth.members = payload
        }, 
        UPLOADS(state, payload) {
            state.auth.uploads = payload
        }, 
    },

    actions: {

    },
})