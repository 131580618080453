import Api from "./Api";

export default {
    get_all_faculty() {
        return Api().get("/teams");
    },

    get_divisions(slug) {
        return Api().get(`/pages/` + slug);
    },

    // get_articles_limiter(limit) {
    //     return Api().get(`/articles?limit=`+limit);
    // },

    get_faculty(slug) {
        return Api().get(`/teams/` + slug);
    },
 
    post_faculty(payload) {
        return Api().post(`/admin/teams`, payload);
    },

    update_faculty(payload) {
        return Api().patch(`/admin/teams/` + payload.slug, payload);
    },

    delete_faculty(payload) {
        return Api().delete(`/admin/teams/` + payload.slug, payload);
    },
}