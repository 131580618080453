<template>
  <v-container fluid>
      <!-- <div> -->
          <v-row class="pt-8 px-16 hidden-sm-and-down">
              <v-col>
                <v-card elevation="8" class="pt-4 pl-4">
                  <v-card-title class="charitytitle">Charity Services</v-card-title>           
                  <v-card-text>
                    <v-carousel height="1000px" continuous :cycle="true" hide-delimiters>
                      <v-carousel-item
                        v-for="(item,i) in filtered"
                        :key="i"
                        :src="item[0].value"
                        reverse-transition="fade-transition"
                        transition="fade-transition"      
                        contain                  
                      > 
                      <!-- <v-row class="fill-height" align="center" justify="center">
                        <div class="display-3 white--text">{{ item.name }}</div>
                      </v-row>                        -->
                      </v-carousel-item>
                    </v-carousel>
                  </v-card-text>               
                </v-card>
              </v-col>
          </v-row>
          <v-row class="hidden-md-and-up">
              <v-col>
                <v-card elevation="8" class="pt-4 pl-4">
                  <v-card-title class="charitytitle1">Charity Services</v-card-title>           
                  <v-card-text>
                    <v-carousel continuous :cycle="true" hide-delimiters>
                      <v-carousel-item
                        v-for="(item,i) in filtered"
                        :key="i"
                        :src="item[0].value"
                        reverse-transition="fade-transition"
                        transition="fade-transition"      
                        contain                  
                      ></v-carousel-item>
                    </v-carousel>
                  </v-card-text>               
                </v-card>
              </v-col>
          </v-row>
      <!-- </div> -->
  </v-container>
</template>

<script>
import Pages from "../apis/Pages";
import { mapGetters } from "vuex";

  export default {
    data () {
      return {
        items1: [
          {
            src: 'https://images.pexels.com/photos/11417275/pexels-photo-11417275.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
          },
          {
            src: 'https://images.pexels.com/photos/11354945/pexels-photo-11354945.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
          },
          {
            src: 'https://images.pexels.com/photos/11368393/pexels-photo-11368393.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
          },
          {
            src: 'https://images.pexels.com/photos/11411623/pexels-photo-11411623.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
          },
        ],
        filtered: []
      }
    },

    computed: {
      ...mapGetters({
        items: "getItems",
        // queue_clinic: "getQueueClinic", 
        // queue_service: "getQueueService",
      }),
    },

    created () {                
        this.getPageContent()
    },

    methods: {
      getPageContent() {
        Pages.get_page('services')
        .then(response => {                
                this.$store.commit('ITEMS', response.data.components_attributes);   
                this.filterer(response.data.components_attributes)
                // this.items =  response.data            
                // console.log('test ', response.data.components_attributes)
            })
            .catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                }
                else if (error.response.status === 404) {
                    this.errors = error.response.data.errors;
                    this.$store.commit('ITEMS', []);   
                }
            });
      },      

      photoChecker(photo) {
        if(photo == '' || photo == null) {
          return this.photo
        }
        else {
          return photo
        }
      },

      filterer(payload) {
        // console.log('payload',payload);
        // var filtered = []
        for(const p in payload) {
            // console.log(payload[p].parts_attributes);
            this.filtered.push(payload[p].parts_attributes
            .filter(key => (key.type_of == 'Image'))
            
            )
        }
        // console.log(this.filtered);
       } ,
    },
  }
</script>

<style scoped>
.charitytitle {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 32px;
    /* identical to box height, or 160% */
    letter-spacing: 0.25px;
    color: #0D09F0;
}
.charitytitle1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 2rem;
    line-height: 32px;
    /* identical to box height, or 160% */
    letter-spacing: 0.25px;
    color: #0D09F0;
}
</style>