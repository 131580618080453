<template>
  <v-container fluid>
    <!-- <div>
      <span><h1 class="text-center headertitle">A Pillar in Philippine Orthopaedics</h1></span>
    </div> -->
    <div class="parent hidden-sm-and-down">
      <v-carousel height="850" class="imgbg" hide-delimiters :show-arrows="false" continuous :cycle="true">
        <v-carousel-item
          v-for="(item,i) in banners"
          :key="i"
          :src="item.value"
          reverse-transition="fade-transition"
          transition="fade-transition"
          class="bg-image"
        >
        <!-- <v-img :src="item.value" contain max-height="625" width="auto"></v-img> -->
        </v-carousel-item>
      </v-carousel>
      <v-carousel height="850" class="imgmain" hide-delimiters :show-arrows="false" continuous :cycle="true">
        <v-carousel-item
          v-for="(item,i) in banners"
          :key="i"
          
          reverse-transition="fade-transition"
          transition="fade-transition"        
        >
        <v-img :src="item.value" contain max-height="850" width="auto"></v-img>
        </v-carousel-item>
      </v-carousel>
      <v-banner  
        class="bannertxt"              
      >
        <b> A PILLAR IN PHILIPPINE ORTHOPAEDICS </b>
      </v-banner>
    </div>
    <div class="parent hidden-md-and-up">
      <v-carousel height="425" class="imgbg" hide-delimiters :show-arrows="false" continuous :cycle="true">
        <v-carousel-item
          v-for="(item,i) in banners"
          :key="i"
          :src="item.value"
          reverse-transition="fade-transition"
          transition="fade-transition"        
        >
        </v-carousel-item>
      </v-carousel>
      <v-banner  
        class="bannertxt1"      
        
      >
        <b> A PILLAR IN PHILIPPINE ORTHOPAEDICS </b>
      </v-banner>
    </div>
    <br>
    <div>
      <span class="headertext">
      Jose R. Reyes Memorial Medical Center Department of Orthopaedics has been committed to the orthopaedic management of all Filipinos.
      <br>
      Serving as a trauma-capable hospital or a trauma center, JRRMMC caters to a variety of multiply-injured patients.
      <br>
      The Department of Orthopaedics serves a vital role in the management of these patients.
      </span>
    </div>
    <!-- <br><br><br>     -->
    <v-row align="center" justify="center" class="exetxt hidden-sm-and-down mt-8">
      <v-col cols="12" align="center" justify="center">
        <h1>Executive Committee </h1>
      </v-col>
    </v-row>
    <v-row align="center" justify="center" class="hidden-sm-and-down mb-8">
      <v-col cols="3" align="center" justify="center">
        <v-img                        
          class="facilitiesimage"
          alt="Facebook"                                
          :src="photoChecker(image_filterer(items[1]))"
          transition="scale-transition"     
          height="300px" 
          width="200px"   
                       
        /> 
        <h1>{{ items[1].name }}</h1>
        <h2>{{ content_filterer(items[1]) }}</h2>
      </v-col>
      <v-col cols="3" align="center" justify="center">
        <v-img                        
          class="facilitiesimage"
          alt="Facebook"                                
          :src="photoChecker(image_filterer(items[2]))"
          transition="scale-transition"     
          height="300px"  
          width="200px"
                       
        /> 
        <h1>{{ items[2].name }}</h1>
        <h2>{{ content_filterer(items[2]) }}</h2>
      </v-col>
      <v-col cols="3" align="center" justify="center">
        <v-img                        
          class="facilitiesimage"
          alt="Facebook"                                
          :src="photoChecker(image_filterer(items[3]))"
          transition="scale-transition"     
          height="300px" 
          width="200px"
                       
        /> 
        <h1>{{ items[3].name }}</h1>
        <h2>{{ content_filterer(items[3]) }}</h2>
      </v-col>
      <v-col cols="3" align="center" justify="center">
        <v-img                        
          class="facilitiesimage"
          alt="Facebook"                                
          :src="photoChecker(image_filterer(items[4]))"
          transition="scale-transition"    
          height="300px" 
          width="200px"
                       
        /> 
        <h1>{{ items[4].name }}</h1>
        <h2>{{ content_filterer(items[4]) }}</h2>
      </v-col>
    </v-row>
    <!-- <v-row align="center" justify="center" class="hidden-sm-and-down">
      <v-col align="center" justify="center">
        <iframe src="https://drive.google.com/file/d/1DvmykC3IVHPrnjWGUUo-2ioRyd-HKxIP/preview" width="800" height="480" allow="autoplay" allowfullscreen></iframe>
      </v-col>
    </v-row>
    <v-row align="center" justify="center" class="hidden-md-and-up">
      <v-col align="center" justify="center">
        <iframe src="https://drive.google.com/file/d/1DvmykC3IVHPrnjWGUUo-2ioRyd-HKxIP/preview" width="100%" height="240" allow="autoplay" allowfullscreen></iframe>
      </v-col>
    </v-row> -->

    <v-row align="center" justify="center" class="exetxt1 hidden-md-and-up mt-4">
      <v-col cols="12" align="center" justify="center">
        <h1>Executive Committee </h1>
      </v-col>
    </v-row>
    <v-row align="center" justify="center" class="hidden-md-and-up mb-4">
      <v-col cols="12" align="center" justify="center">
        <v-img                        
          class="facilitiesimage"
          alt="Facebook"                                
          :src="photoChecker(image_filterer(items[1]))"
          transition="scale-transition"     
          height="300px" 
          width="200px"
                       
        /> 
        <h2>{{ items[1].name }}</h2>
        <h3 class="mb-4">{{ content_filterer(items[1]) }}</h3>
      <!-- </v-col> -->
      <!-- <v-col cols="4" align="center" justify="center"> -->
        <v-img                        
          class="facilitiesimage"
          alt="Facebook"                                
          :src="photoChecker(image_filterer(items[2]))"
          transition="scale-transition"     
          height="300px" 
          width="200px"
                       
        /> 
        <h2>{{ items[2].name }}</h2>
        <h3 class="mb-4">{{ content_filterer(items[2]) }}</h3>
      <!-- </v-col> -->
      <!-- <v-col cols="4" align="center" justify="center"> -->
        <v-img                        
          class="facilitiesimage"
          alt="Facebook"                                
          :src="photoChecker(image_filterer(items[3]))"
          transition="scale-transition"     
          height="300px" 
          width="200px"
                       
        /> 
        <h2>{{ items[3].name }}</h2>
        <h3 class="mb-4">{{ content_filterer(items[3]) }}</h3>
        <v-img                        
          class="facilitiesimage"
          alt="Facebook"                                
          :src="photoChecker(image_filterer(items[4]))"
          transition="scale-transition"     
          height="300px" 
          width="200px"
                       
        /> 
        <h2>{{ items[4].name }}</h2>
        <h3 class="mb-4">{{ content_filterer(items[4]) }}</h3>
      </v-col>
    </v-row>

    <v-row align="center" justify="center">
      <v-col cols="sm-10">
        <v-row>
        <v-col>
          <div>
            <span class="newsfont">
              Latest News  
              <v-spacer></v-spacer>              
              <a href="/news"  class="seeallfont">See all</a></span>      
          </div>
        </v-col>
      </v-row>
      <v-row class="mb-4 hidden-sm-and-down">        
        <v-col
          v-for="a in articles"
          :key="a.id"
          cols="4"
        >         
          <v-card class="newscard" elevation="6">
            <v-img
              :src="photoChecker(a.photo_url)"
              class="white--text align-end"                            
              height="500"
            >
              
            </v-img>
            <v-card-title class="newstitle justify-center">{{ a.title }}</v-card-title>
            <v-card-text>
              <span class="newscontent" v-html="a.content">
                <!-- {{ a.content }} -->
              </span>
            </v-card-text>
            <!-- <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn icon>
                <v-icon>mdi-heart</v-icon>
              </v-btn>

              <v-btn icon>
                <v-icon>mdi-bookmark</v-icon>
              </v-btn>

              <v-btn icon>
                <v-icon>mdi-share-variant</v-icon>
              </v-btn>
            </v-card-actions> -->
          </v-card>
        </v-col>
      </v-row>

      <v-row class="mb-4 hidden-md-and-up">        
        <v-col
          v-for="a in articles"
          :key="a.id"
          cols="12"
        >         
          <v-card class="newscard" elevation="6">
            <v-img
              :src="photoChecker(a.photo_url)"
              class="white--text align-end"              
              height="500"
            >
              
            </v-img>
            <v-card-title class="newstitle justify-center">{{ a.title }}</v-card-title>
            <v-card-text>
              <span class="newscontent" v-html="a.content">
                <!-- {{ a.content }} -->
              </span>
            </v-card-text>
            <!-- <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn icon>
                <v-icon>mdi-heart</v-icon>
              </v-btn>

              <v-btn icon>
                <v-icon>mdi-bookmark</v-icon>
              </v-btn>

              <v-btn icon>
                <v-icon>mdi-share-variant</v-icon>
              </v-btn>
            </v-card-actions> -->
          </v-card>
        </v-col>
      </v-row>
      <!-- <v-row>
        <v-col>
          <div class="mvdiv1">
            <span class="mvtitle">Our Mission</span>
            <br><br>
            <span class="mvtext">We commit to the unconditional delivery of excellent orthopedic care centered on the well-being of patients through competence in training.</span>
          </div>
        </v-col>
      </v-row>
      <br><br>
      <v-row>
        <v-col>
          <div class="mvdiv2">
            <span class="mvtitle">Our Vision</span>
            <br><br>
            <span class="mvtext">To be the primary choice for specialized orthopedic care in the Philippines.</span>
          </div>
        </v-col>
      </v-row> -->
      </v-col>
    </v-row>
    <!-- <br><br><br> -->
  </v-container>
</template>

<script>
import News from "../apis/News";
import Pages from "../apis/Pages";
import { mapGetters } from "vuex";

  export default {
    name: 'HelloWorld',
    data () {
      return {
        // items: [
        //   {
        //     value: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg',
        //   },
        //   {
        //     value: 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',
        //   },
        //   {
        //     value: 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',
        //   },
        //   {
        //     value: 'https://cdn.vuetifyjs.com/images/carousel/planet.jpg',
        //   },
        // ],
        cards: [
          // { title: 'Kyoti ng Grande', src: require('@/assets/images/erning.jpg'), flex: 4 },
          // { title: 'Pantasya ng Guada', src: require('@/assets/images/kimaks.jpg'), flex: 4 },
          { title: 'Best airlines', src: 'https://cdn.vuetifyjs.com/images/cards/plane.jpg', flex: 4 },
          { title: 'Favorite road trips', src: 'https://cdn.vuetifyjs.com/images/cards/road.jpg', flex: 4 },
          { title: 'Best airlines', src: 'https://cdn.vuetifyjs.com/images/cards/plane.jpg', flex: 4 },          
          // { title: 'Kilabot ng Accenture', src: require('@/assets/images/mamba.jpg'), flex: 4 },
        ],        
        photo: '/assets/images/JRRMMC_Ortho_Logo.jpg'
      }
    },

    computed: {
      ...mapGetters({
        banners: "getBanners", 
        articles: "getArticles", 
        items: "getItems",
        // queue_clinic: "getQueueClinic", 
        // queue_service: "getQueueService",
      }),
    },

    created () {        
        this.getHomeContent()
        this.getNewsContent()
        // localStorage.setItem('prev_route',this.$router.currentRoute.path)
    },

    methods: {
      getHomeContent() {
        Pages.get_page('home')
            .then(response => {                
                this.$store.commit('BANNERS', response.data.components_attributes[0].parts_attributes);
                this.$store.commit('ITEMS', response.data.components_attributes);
                // console.log(response.data.components)
            })
            .catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    // this.snackbar_text = "Unable to load clinics!"
                    // this.snackbar_color = 'red darken-2'
                    // this.snackbar = true
                }
                else if (error.response.status === 404) {
                    this.errors = error.response.data.errors;
                    // this.snackbar_text = "Unable to load clinics!"
                    // this.snackbar_color = 'red darken-2'
                    // this.snackbar = true
                }
            });
      },

      getNewsContent() {
        News.get_articles_limiter(3)
            .then(response => {                
                this.$store.commit('ARTICLES', response.data);   
                // this.items =  response.data            
                // console.log('test ', response.data)
            })
            .catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    // this.snackbar_text = "Unable to load clinics!"
                    // this.snackbar_color = 'red darken-2'
                    // this.snackbar = true
                }
                else if (error.response.status === 404) {
                    this.errors = error.response.data.errors;
                    this.$store.commit('ARTICLES', []);   
                    // this.items = []
                    // this.snackbar_text = "Unable to load clinics!"
                    // this.snackbar_color = 'red darken-2'
                    // this.snackbar = true
                }
            });
      },

      photoChecker(photo) {
        if(photo == '' || photo == null) {
          return this.photo
        }
        else {
          return photo
        }
      },      

      image_filterer(payload) {
          var filtered_images = []
          filtered_images.push(payload.parts_attributes.filter(key => (key.type_of == 'Image')))
          if(filtered_images[0] == '') {
            return  ''        
          }
          else {
            return filtered_images[0][0].value
          }          
       },

       content_filterer(payload) {
          var filtered_content = []
          filtered_content.push(payload.parts_attributes.filter(key => (key.type_of == 'Content')))
          if(filtered_content[0] == '') {
            return  ''        
          }
          else {
            return filtered_content[0][0].value
          }             
       },
    }
  }
</script>

<style scoped>
.headertitle {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 2.75rem;
  /* font-size: calc(2vw + 2vh + 2vmin); */
  /* line-height: 43px; */
  /* display: flex; */
  /* position: inherit; */
  /* align-items: center !important;
  text-align: center !important; */
  /* text-transform: capitalize; */
  margin-bottom: 15px;
  /* padding: 20px; */
  color: #000000;
}
.headertext {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 1.25rem;  
  /* font-size: calc(1vw + 1vh + 1vmin); */
  /* font-size: 10vw; */
  /* line-height: 43px; */
  display: block;
  /* position: inherit; */
  align-items: center;
  text-align: center;
  /* text-transform: capitalize; */
  /* margin-bottom: 15px;
  padding: 20px; */
  color: #000000;
}
.parent {
  position: relative;
  top: 0;
  left: 0;
}

.bannertxt {
  z-index: 2;
  position:absolute;
  top: 725px;  
  /* left: 180px; */
  width: 100%;
  /* padding: 10px; */
  font-size: 2.75rem;
  /* background-color: #FFFFFF !important; */
  background: rgb(0,0,0,25%) !important;
  color: #ffffff;
  text-align: center;  
}

.bannertxt1 {
  z-index: 2;
  position:absolute;
  top: 325px;  
  /* left: 180px; */
  width: 100%;
  /* padding: 10px; */
  font-size: 1.25rem;
  /* background-color: #FFFFFF !important; */
  background: rgb(0,0,0,25%) !important;
  color: #ffffff;
  text-align: center;
  align-items: right;
}

/* .v-banner__wrapper {
    align-items: center;
    display: flex;
    flex: 1 1 auto;
    background: white;    
} */

.imgbg {
  position:relative;
  /* top: 180px;
  left: 10px; */
  z-index: 1;
  max-width: 100%;
  height: auto;

}
.imgmain {
  position:absolute;
  top: 0px;  
  left: 180px;
  max-width: 80%;  
  z-index: 2;
  height: auto;

}
.bg-image {
  filter: blur(8px);
  -webkit-filter: blur(8px);
}

.exetxt {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 43px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  color: #2E47CB;
}

.exetxt1 {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 43px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  color: #2E47CB;
}

.newsflex {
  display: flex;
  /* align-items: center; */
  justify-content: center;
}
.newsfont {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 1.75rem;
  line-height: 43px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  color: #2E47CB;
}

.newsfont .seeallfont{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 43px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  color: #2E47CB;
  text-decoration: none;
}

.newscard {
  background: #FFFFFF;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 5px;
  height: 900px;  
  
}

.newstitle {
  font-size: 1.25rem;
  word-break: break-word;
  text-align: center;
}

.newscontent {
  display: inline-block;
  /* border: 1px solid #cccccc; */
  /* padding: 10px; */
  width: 100%;
  /* height: 325px; */
  -webkit-line-clamp: 8;
  display: -webkit-box;
  line-height: 1.5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis !important;
  font-size: 1rem;
  text-align: justify;
}

.mvtitle {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 1.75rem;
  /* font-size: calc(1vw + 1vh + 1vmin); */
  /* line-height: 43px; */
  display: block;
  /* position: inherit; */
  align-items: center;
  text-align: center;
  /* text-transform: capitalize; */
  /* margin-bottom: 15px;
  padding: 20px; */
  color: #000000;
}

.mvtext {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 1.25rem;
  /* font-size: calc(0.75vw + 0.75vh + 0.75vmin); */
  /* line-height: 43px; */
  display: block;
  /* position: inherit; */
  align-items: center;
  text-align: center;
  /* text-transform: capitalize; */
  /* margin-bottom: 15px;
  padding: 20px; */
  color: #000000;
}
  
.mvdiv1 {
  margin-top: 25px;
  margin-bottom: 25px;
}

.mvdiv2 {
  /* margin-top: 50px; */
  margin-bottom: 50px;
}


</style>
