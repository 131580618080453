<template>
  <v-container fluid>
      <div class="hidden-sm-and-down">
          <v-row class="pt-8 px-16">
              <v-col>
                <v-card elevation="8" class="pt-4 pl-4">
                  <v-card-title class="researchtitle">Research</v-card-title>           
                  <v-card-text class="pt-8">
                    <v-row 
                      class="pt-4"
                      v-for="(item,i) in research"
                      :key="i"
                      align="center" justify="center"
                    >
                      <v-col class="researchcontent" align="center" justify="center">
                        <span class="researchcontent">{{content_filterer(item)}}</span>
                      </v-col>
                      <v-col>
                        <v-img                                                       
                        alt="Research"
                        height="500" 
                        width="1000"      
                        class="researchimg"   
                        :src="image_filterer(item)"
                        transition="scale-transition"                    
                        />
                        
                      </v-col>                                                                  
                    </v-row>
                    <!-- <v-carousel hide-delimiters>
                      <v-carousel-item
                        v-for="(item,i) in filtered"
                        :key="i"
                        :src="item[0].value"
                        reverse-transition="fade-transition"
                        transition="fade-transition"      
                        contain                                           
                      >
                     
                      </v-carousel-item>
                    </v-carousel> -->
                  </v-card-text>  
                   <!-- <v-row class="fill-height" align="center" justify="center">
                        <div class="display-3 black--text">{{ item[0].value }}</div>
                      </v-row> -->     
                  <!-- <v-card-text class="researchcontent">
                    {{ item }}
                  </v-card-text>         -->
                </v-card>
              </v-col>
          </v-row>
          <v-row class="pt-8 px-16">
            <v-col>
              <v-card elevation="8" class="pt-4 pl-4">
                <v-card-title class="researchtitle">
                  Research Committee
                </v-card-title>
                <!-- <v-row align="center" justify="center" class="exetxt">
                  <v-col cols="12" align="center" justify="center">
                    <h1>Executive Committee </h1>
                  </v-col>
                </v-row> -->
                <v-card-text>
                  <v-row align="center" justify="center">
                    <v-col 
                      align="center" justify="center"
                      cols="3"
                      v-for="(r1, r1index) in researchcommittee"
                      :key="r1index"
                    >                      
                      <v-img                                                            
                      alt="Resident"                                              
                      :src="image_filterer(r1)"
                      transition="scale-transition"     
                      contain    
                      height="300"   
                      width="200px"          
                      />   
                      <v-card-title class="blue--text justify-center text-h5 nametxt"><b>{{ r1.name }}</b></v-card-title>     
                      <!-- <v-card-text class="red--text text-center text-h6"><i>{{ content_filterer(r1) }}</i></v-card-text>             -->
                    </v-col>
                  <!-- <v-col cols="3" align="center" justify="center">
                    <v-img                        
                      class="facilitiesimage"
                      alt="Facebook"                                
                      :src="photoChecker(image_filterer(items[3]))"
                      transition="scale-transition"     
                      height="300px" 
                      width="200px"   
                                  
                    /> 
                    <h1 class="blue--text justify-center text-h5 nametxt"><b>{{ items[3].name }}</b></h1>
                    <h2>{{ content_filterer(items[3]) }}</h2>
                  </v-col>
                  <v-col cols="3" align="center" justify="center">
                    <v-img                        
                      class="facilitiesimage"
                      alt="Facebook"                                
                      :src="photoChecker(image_filterer(items[4]))"
                      transition="scale-transition"     
                      height="300px"  
                      width="200px"
                                  
                    /> 
                    <h1 class="blue--text justify-center text-h5 nametxt"><b>{{ items[4].name }}</b></h1>
                    <h2>{{ content_filterer(items[4]) }}</h2>
                  </v-col>
                  <v-col cols="3" align="center" justify="center">
                    <v-img                        
                      class="facilitiesimage"
                      alt="Facebook"                                
                      :src="photoChecker(image_filterer(items[5]))"
                      transition="scale-transition"     
                      height="300px" 
                      width="200px"
                                  
                    /> 
                    <h1 class="blue--text justify-center text-h5 nametxt"><b>{{ items[5].name }}</b></h1>
                    <h2>{{ content_filterer(items[5]) }}</h2>
                  </v-col>             -->
                </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <br>
      </div>
      <div class="hidden-md-and-up">
          <v-row>
              <v-col>
                <v-card elevation="8" class="pt-4 px-2">
                  <v-card-title class="researchtitle">Research</v-card-title>           
                  <v-card-text class="pt-8">
                    <v-row 
                      class="pt-4"
                      v-for="(item,i) in research"
                      :key="i"
                      align="center" justify="center"
                    >
                      <v-col>
                        <v-img       
                        class="researchimg"                                                   
                        alt="Research"                        
                        width="500"         
                        :src="image_filterer(item)"
                        transition="scale-transition"                    
                        />
                        
                      </v-col>                      
                      <v-col class="researchcontent1" align="center" justify="center">
                        <span>{{content_filterer(item)}}</span>
                      </v-col>
                      
                    </v-row>
                    <!-- <v-carousel hide-delimiters>
                      <v-carousel-item
                        v-for="(item,i) in filtered"
                        :key="i"
                        :src="item[0].value"
                        reverse-transition="fade-transition"
                        transition="fade-transition"      
                        contain                                           
                      >
                     
                      </v-carousel-item>
                    </v-carousel> -->
                  </v-card-text>  
                </v-card>
              </v-col>
          </v-row>     
          <v-row>
            <v-col>
              <v-card elevation="8" class="pt-4 px-2">
                <v-card-title class="researchtitle">Research Committee</v-card-title>           
                <v-card-text class="pt-8">
                  <v-row align="center" justify="center">
                      <v-col 
                        align="center" justify="center"
                        cols="12"
                        v-for="(r1, r1index) in researchcommittee"
                        :key="r1index"
                      >                      
                        <v-img                                                            
                        alt="Resident"                                              
                        :src="image_filterer(r1)"
                        transition="scale-transition"     
                        contain 
                        width="50%"           
                        />   
                        <v-card-title class="blue--text justify-center text-h5 nametxt"><b>{{ r1.name }}</b></v-card-title>     
                        <!-- <v-card-text class="red--text text-center text-h6"><i>{{ content_filterer(r1) }}</i></v-card-text>             -->
                      </v-col>
                    <!-- <v-col align="center" justify="center">
                      <v-img                        
                        class="facilitiesimage"
                        alt="Facebook"                                
                        :src="photoChecker(image_filterer(items[3]))"
                        transition="scale-transition"     
                        height="300px" 
                        width="200px"   
                                    
                      /> 
                      <h2 class="blue--text justify-center text-h6 nametxt">{{ items[3].name }}</h2>
                      <h3 class="mb-4">{{ content_filterer(items[3]) }}</h3>
  
                      <v-img                        
                        class="facilitiesimage"
                        alt="Facebook"                                
                        :src="photoChecker(image_filterer(items[4]))"
                        transition="scale-transition"     
                        height="300px"  
                        width="200px"
                                    
                      /> 
                      <h2 class="blue--text justify-center text-h6 nametxt">{{ items[4].name }}</h2>
                      <h3 class="mb-4">{{ content_filterer(items[4]) }}</h3>
               
                      <v-img                        
                        class="facilitiesimage"
                        alt="Facebook"                                
                        :src="photoChecker(image_filterer(items[5]))"
                        transition="scale-transition"     
                        height="300px" 
                        width="200px"
                                    
                      /> 
                      <h2 class="blue--text justify-center text-h6 nametxt">{{ items[5].name }}</h2>
                      <h3 class="mb-4">{{ content_filterer(items[5]) }}</h3>
                    </v-col>             -->
                  </v-row>
                </v-card-text>  
              </v-card>
            </v-col>
          </v-row>     
          <br>
      </div>
  </v-container>
</template>

<script>
import Pages from "../apis/Pages";
import { mapGetters } from "vuex";

  export default {
    name: 'ResearchPage',
    data () {
      return {
        filtered: [],
        research: {},
        researchcommittee: [],
        // items: [
        //   {
        //     src: 'https://images.pexels.com/photos/1462935/pexels-photo-1462935.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
        //   },
        //   {
        //     src: 'https://images.pexels.com/photos/6492953/pexels-photo-6492953.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
        //   },
        //   {
        //     src: 'https://images.pexels.com/photos/4593505/pexels-photo-4593505.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
        //   },
        //   {
        //     src: 'https://images.pexels.com/photos/4341403/pexels-photo-4341403.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
        //   },
        // ],
      }
    },

    computed: {
      ...mapGetters({
        // banners: "getBanners", 
        // articles: "getArticles", 
        items: "getItems",
        // queue_clinic: "getQueueClinic", 
        // queue_service: "getQueueService",
      }),
    },

    created () {        
        // this.getNewsContent()
        this.getPageContent()
    },

    methods: {
      getPageContent() {
        Pages.get_page('research')
        .then(response => {                
                this.$store.commit('ITEMS', response.data.components_attributes); 
                this.research = response.data.components_attributes.slice(0,3)  
                this.filterer(response.data.components_attributes)
                // this.items =  response.data            
                // console.log('test ', response.data.components_attributes)
                let l =  response.data.components_attributes.length
                var committee = response.data.components_attributes.slice(2,l)   
                this.researchcommittee = this.filterByProperty(committee, "value", "Research Committee")
            })
            .catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                }
                else if (error.response.status === 404) {
                    this.errors = error.response.data.errors;
                    this.$store.commit('ITEMS', []);   
                }
            });
      },     

      photoChecker(photo) {
        if(photo == '' || photo == null) {
          return this.photo
        }
        else {
          return photo
        }
      },

      filterer(payload) {
        // console.log('payload',payload);
        // var filtered = []
        for(const p in payload) {
            // console.log(payload[p].parts_attributes);
            this.filtered.push(payload[p].parts_attributes
            .filter(key => (key.type_of == 'Image'))
            
            )
        }
        // console.log(this.filtered);
       } ,

      image_filterer(payload) {
          var filtered_images = []
          filtered_images.push(payload.parts_attributes.filter(key => (key.type_of == 'Image')))
          if(filtered_images[0] == '') {
            return  ''        
          }
          else {
            return filtered_images[0][0].value
          }          
       },

      content_filterer(payload) {
        var filtered_content = []
        filtered_content.push(payload.parts_attributes.filter(key => (key.type_of == 'Content')))
        if(filtered_content[0] == '') {
          return  ''        
        }
        else {
          return filtered_content[0][0].value
        }             
      },

      filterByProperty(array, prop, value){
            // console.log('arr', array)
            // console.log('prp', prop)
            // console.log('vle', value)
            var filtered = [];
            for(var i = 0; i < array.length; i++){

                var obj = array[i];
                // console.log('obj', obj.parts_attributes)
                for(var key in obj.parts_attributes){
                  // console.log('objPAR', obj[key].parts_attributes)
                  // eslint-disable-next-line
                    if(typeof(obj[key] == "object")){
                        var item = obj.parts_attributes[key];
                        // console.log('itm1', item)
                        if(item[prop].match(value)){
                          // console.log('itm2', item)
                            filtered.push(obj);
                        }
                    }
                }

            }    
            console.log('fltrd', filtered)
            return filtered;

        }   
    }
  }
  
</script>

<style scoped>
.researchtitle {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 32px;
    /* identical to box height, or 160% */
    letter-spacing: 0.25px;
    color: #0D09F0;
    word-break: break-word;
}
.researchimg {
   border: 2px solid #0D09F0;
    box-sizing: border-box;
    border-radius: 20px;
    background: white;
    /* width: 500px; */
}
.researchcontent {  
  font-family: 'Roboto';
  font-style: normal;
  text-align: center !important;
  font-size: 2rem;
  padding: auto;
  color: black !important;
  word-break: break-word !important;
  line-height: 50px;
}

.researchcontent1 {
  font-family: 'Roboto';
  font-style: normal;
  text-align: center;
  font-size: 1rem;
  color: black !important;
}

.nametxt{  
  word-break: break-word;
  color: black !important
}
</style>