<template>
  <div >
    <v-tabs
      class="hidden-sm-and-down"
      grow
      background-color="#2E47CB"
      dark
      height="83"        
    >
      <!-- <router-link to="/" style="text-decoration: none; color: inherit;"> -->
      <v-tab to="/"> 
        <!-- Home -->      
          Home        
      </v-tab>
      <!-- </router-link> -->
      <v-tab to="/divisions-and-services">
        Divisions and Services
        <!-- <router-link to="divisions-and-services" style="text-decoration: none; color: inherit;">
          Divisions and Service
        </router-link> -->
      </v-tab>
      <v-tab to="/facilities">
        Facilities
      </v-tab>
      <v-tab to="/training">
        Training
      </v-tab>
      <v-tab to="/charity-services">
        Charity Services
      </v-tab>
      <v-tab to="/alumni">
        Alumni
      </v-tab>
      <v-tab to="/post_grad">
        Post Graduate Course
      </v-tab>
      <!-- <v-tab to="/pre-residency">
        Pre-Residency
      </v-tab> -->
      <v-tab to="/research">
        Research
      </v-tab>      
      <v-tab to="/news">
        News
      </v-tab>
      <v-tab to="contact-us">
        Contact Us
      </v-tab>
    </v-tabs>  

    <v-select          
          color="#0D09F0"
          v-model="selected_page"
          class="mt-8 px-2 hidden-md-and-up"        
          :items="pages"
          placeholder="Page Navigation"
          item-text="tab"
          item-value="slug"
          outlined
          return-object
          @input="changePage(selected_page)"
      >
      </v-select>  

    <v-main>
      <!-- <v-container fluid> -->
          <!-- <v-row class="fill-height">
              <v-col> -->
                
                  <transition name="fade">
                    <router-view></router-view>
                  </transition>
              <!-- </v-col>
          </v-row> -->
      <!-- </v-container> -->
    </v-main>
  </div>
  
</template>

<script>
  export default {
    name: 'NavigationBar',

    data: () => ({
      pages: [
          { tab: 'Home', content: 'Home', slug: 'home' },
          { tab: 'Divisions and Services', content: 'Divisions and Services', slug: 'divisions' },
          { tab: 'Facilities', content: 'Facilities', slug: 'facilities' },
          { tab: 'Training', content: 'Training', slug: 'training' },
          { tab: 'Charity Services', content: 'Charity Services', slug: 'services' },
          { tab: 'Alumni', content: 'Alumni', slug: 'alumni' },
          { tab: 'Research', content: 'Research', slug: 'research' },
          { tab: 'Post Graduate Course', content: 'Post Graduate Course', slug: 'post_grad' },
          { tab: 'Pre-Residency', content: 'Pre-Residency', slug: 'pre-residency' },
          { tab: 'News', content: 'Articles', slug: 'articles' },     
          { tab: 'Contact Us', content: 'Contact Us', slug: 'contact' },                           
        ],
        selected_page: ''
    }),

    mounted() {
      // console.log(localStorage.getItem('prev_route'))
    },

    methods: {
      changePage(page) {        
        this.selected_page = page.slug
        if(page.slug == 'home') {          
          
          this.$router.push( { name: "Home" } )
        }
        else if(page.slug == 'divisions') {
          this.$router.push( { name: "DivisionsServices" } )
        }
        else if(page.slug == 'facilities') {
          console.log(localStorage.getItem('route'))
          this.$router.push( { name: "Facilities" } )
        }
        else if(page.slug == 'training') {
          this.$router.push( { name: "Training" } )
        }
        else if(page.slug == 'services') {
          this.$router.push( { name: "CharityServices" } )
        }
        else if(page.slug == 'alumni') {
          this.$router.push( { name: "Alumni" } )
        }
        else if(page.slug == 'research') {
          this.$router.push( { name: "Research" } )
        }
        else if(page.slug == 'post_grad') {
          this.$router.push( { name: "PostGraduateCourse" } )
        }
        else if(page.slug == 'pre-residency') {
          this.$router.push( { name: "PreResidency" } )
        }
        else if(page.slug == 'articles') {
          this.$router.push( { name: "News" } )
        }
        else if(page.slug == 'contact') {
          this.$router.push( { name: "ContactUs" } )
        }
        else {
          this.$router.push( { name: "Home" } )
        }
      }
    }
  }
</script>

<style scoped>
.v-tab {
  font-size: 1.5rem;
  letter-spacing: 0.05rem;
}
</style>
